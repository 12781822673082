<template>
  <div @mouseleave="toggleOurToolsMenu(false)">
    <div class="h-full flex lg:justify-center cursor-pointer" @click="toggleOurToolsMenu(!show)">
      <span
        class="link font-sans h-14 lg:h-auto p-2 block font-semibold text-lg lg:text-base lg:px-3 lg:flex lg:items-center lg:border-gray-dark text-blue"
        v-text="item.label"
      />
      <PtIcon
        :class="[
          'relative bottom-1 lg:bottom-0 self-center -ml-1 text-xl cursor-pointer transform transition-transform',
          { 'rotate-180': show }
        ]"
        color="blue"
        name="angle-down"
      />
    </div>
    <transition
      enter-active-class="duration-500"
      leave-active-class="duration-300"
      enter-class="opacity-0"
      leave-to-class="opacity-0"
    >
      <div
        v-show="show"
        class="lg:fixed w-full lg:left-0 p-2 lg:p-0 lg:shadow-pt"
        :style="`max-width: ${windowWidth >= 1024 ? '100%' : '290px'}; ${
          windowWidth >= 1024 ? 'top: 70px;' : undefined
        }`"
        @mouseover="toggleOurToolsMenu(true)"
      >
        <div class="relative overflow-hidden">
          <div class="absolute w-full h-full hidden lg:block bg-orange-pastel" />
          <div class="lg:container lg:relative lg:grid grid-cols-12">
            <div v-if="data?.main_link?.url" class="lg:col-span-5 lg:py-10 lg:pr-24">
              <span
                class="font-ubuntu font-medium text-sm text-gray"
                v-text="data.main_item_title"
              />
              <a
                :href="`${spaceUrl}${data.main_link.url}#sso`"
                class="flex items-center lg:items-start mt-5 lg:mt-2.5 -m-1.5 lg:-mx-2.5 -mb-1.5 lg:-mb-2.5"
              >
                <PtIcon
                  class="m-1.5 lg:m-2.5 p-1.5 lg:p-2.5 rounded lg:text-2xl bg-green-very-light"
                  color="white"
                  :name="data.main_link.icon"
                />
                <div class="m-1.5 lg:m-2.5 hover:underline">
                  <img
                    class="w-20 lg:w-28 lg:mb-5"
                    :src="data.media.url"
                    :alt="data.media.alternativeText"
                  />
                  <p class="hidden lg:block text-sm text-gray" v-text="data.main_link.label" />
                </div>
              </a>
            </div>
            <div class="lg:relative lg:col-span-7 mt-5 lg:mt-0">
              <div class="relative lg:h-full z-10 lg:py-10 lg:pl-24">
                <span
                  class="font-ubuntu font-medium text-sm text-gray"
                  v-text="data?.secondary_item_title"
                />
                <div v-if="data?.secondary_items" class="w-full flex flex-wrap mt-2 -mb-2 -mx-2">
                  <template v-for="(link, indexLink) in data.secondary_items">
                    <div
                      v-if="checkCurrentDomain(link)"
                      :key="indexLink"
                      class="w-full lg:w-5/12 flex flex-auto m-2"
                    >
                      <a
                        v-if="link.url"
                        :class="[
                          'flex items-center self-start cursor-pointer',
                          { [`btn ${link.type}`]: /btn/gi.test(link.type) }
                        ]"
                        :href="`${spaceUrl}${link.url}#sso`"
                      >
                        <PtIcon v-if="link.icon" class="text-xl" color="blue" :name="link.icon" />
                        <span
                          :class="[
                            'block font-ubuntu font-bold text-sm lg:text-lg',
                            /btn/gi.test(link.type)
                              ? 'whitespace-nowrap'
                              : 'm-2 hover:underline hover:decoration-blue text-blue'
                          ]"
                          v-text="link.label"
                        />
                      </a>
                    </div>
                  </template>
                </div>
              </div>
              <div class="absolute h-full hidden lg:block w-screen top-0 bg-white" />
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import PtIcon from '../Icon';
import { api } from '@pt/services';
import { useI18n } from 'vue-i18n';
import { reactive, toRefs } from 'vue';
export default {
  name: 'PtMegamenu',
  components: { PtIcon },
  props: {
    item: { type: Object, required: true },
    spaceUrl: { type: String, default: '' },
    windowWidth: { type: Number, default: 0 }
  },
  setup() {
    const { locale } = useI18n();
    const state = reactive({
      show: false,
      data: {}
    });
    const { show, data } = toRefs(state);
    (async () => {
      const { products_megamenu: megamenu } = await api.getCommons(locale.value);
      data.value = megamenu;
    })();
    const toggleOurToolsMenu = state => (show.value = state);
    const checkCurrentDomain = item => !/miespacio/.test(item.url);
    return {
      show,
      data,
      checkCurrentDomain,
      toggleOurToolsMenu
    };
  }
};
</script>
