import './set-public-path';
import './styles/index.css';
import { createApp } from 'vue';
import store from './store';
// You can export Vue components from this file and import them into your microfrontends
export { default as PtMap } from './components/Map';
export { default as PtList } from './components/List';
export { default as PtForm } from './components/Form';
export { default as PtIcon } from './components/Icon';
export { default as PtCard } from './components/Card';
export { default as PtTable } from './components/Table';
export { default as PtTitle } from './components/Title';
export { default as PtPopup } from './components/Popup';
export { default as PtModal } from './components/Modal';
export { default as PtAlert } from './components/Alert';
export { default as PtChart } from './components/Chart';
export { default as PtSearch } from './components/Search';
export { default as PtButton } from './components/Button';
export { default as PtLoading } from './components/Loading';
export { default as PtSidebar } from './components/Sidebar';
export { default as PtToolbar } from './components/Toolbar';
export { default as PtWeather } from './components/Weather';
export { default as PtGallery } from './components/Gallery';
export { default as PtFilters } from './components/Filters';
export { default as PtDropdown } from './components/Dropdown';
export { default as PtHeadline } from './components/Headline';
export { default as PtCalendar } from './components/Calendar';
export { default as PtMenuItem } from './components/MenuItem';
export { default as PtMegamenu } from './components/Megamenu';
export { default as PtAccordion } from './components/Accordion';
export { default as PtBreadcrumbs } from './components/Breadcrumbs';
export { default as PtWeatherDay } from './components/Weather/WeatherDay';
export { default as PtWeatherList } from './components/Weather/WeatherList';
export { default as PtWeatherWeekly } from './components/Weather/WeatherWeekly';
export { default as PtWeatherDayParts } from './components/Weather/WeatherDayParts';
export { default as PtPopupConditional } from './components/Popup/PopupConditional';
export { default as PtWeatherDayDetail } from './components/Weather/WeatherDay/WeatherDayDetail';

const app = createApp({});
app.use(store);
export default app;
