<template>
  <div class="pt-weather">
    <WeatherDay :first-day="firstDay" :farm="farm" :dynamic-text="dynamicText" />
    <WeatherList :weather-hours="weatherHours" :today-text="todayText" />
    <div class="flex justify-end w-full">
      <router-link :to="url" class="pt-link-control" @click="emitAction">
        {{ textAction }}
      </router-link>
    </div>
  </div>
</template>

<script>
import WeatherDay from './WeatherDay';
import WeatherList from './WeatherList';
import { computed } from 'vue';
export default {
  name: 'PtWeather',
  components: {
    WeatherDay,
    WeatherList
  },
  props: {
    weatherHours: {
      type: Array,
      default: () => []
    },
    weatherDays: {
      type: Array,
      default: () => []
    },
    todayText: {
      type: String,
      default: 'Ahora'
    },
    maxText: {
      type: String,
      default: ''
    },
    minText: {
      type: String,
      default: ''
    },
    farm: {
      type: Object,
      default: () => {}
    },
    textAction: {
      type: String,
      default: ''
    },
    url: {
      type: String,
      default: ''
    }
  },
  emits: ['showDetail'],
  setup(props, { emit }) {
    const firstDay = computed(() => props.weatherDays[0] || {});
    const dynamicText = computed(() => ({
      todayText: props.todayText,
      maxText: props.maxText,
      minText: props.minText
    }));
    const emitAction = () => {
      emit('showDetail');
    };

    return {
      firstDay,
      dynamicText,
      emitAction
    };
  }
};
</script>
<style lang="css">
.pt-weather {
  min-height: 400px;
  @apply flex flex-col items-start justify-between space-y-5 p-4 text-center overflow-hidden rounded-lg shadow-md transform transition-all bg-white;
}
</style>
