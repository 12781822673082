<template>
  <div class="pt-scroll w-full overflow-x-auto pb-1">
    <div class="flex min-w-full flex-nowrap">
      <div
        v-for="(part, index) in dayParts"
        :key="index"
        class="p-4 rounded-lg shadow-md border border-gray-very-light transform transition-all bg-white w-56 flex-shrink-0 mr-2"
      >
        <div class="flex justify-between items-center mb-3">
          <div class="flex flex-col text-gray font-bold text-base">
            <span>{{ part.name }}</span>
            <span>{{ part.hoursRange }}</span>
          </div>
          <div class="flex items-center space-x-3">
            <icon :name="`pt-weather-${part.iconCode}`" type="svg" />
            <span class="text-2xl font-semibold"> {{ part.temperature }} º</span>
          </div>
        </div>
        <ul>
          <li
            v-for="(item, i) in properties"
            :key="item.property"
            class="flex justify-between items-center text-xs my-2"
          >
            <div class="flex items-center">
              <icon :name="`pt-${defaultIcons[i]}`" class="text-base mr-2" color="gray-dark" />
              <span class="text-gray-dark">{{ item.label }}</span>
            </div>

            <span class="font-bold">{{ part[item.property] }}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/Icon';

export default {
  name: 'PtWeatherDayParts',
  components: {
    Icon
  },

  props: {
    dayParts: {
      type: Array,
      default: () => []
    },
    properties: {
      type: Array,
      default: () => []
    }
  },
  setup() {
    const defaultIcons = ['cloud', 'rain', 'wind', 'drop', 'clouds', 'eye', 'snow'];
    return { defaultIcons };
  }
};
</script>
<style lang="scss"></style>
