<template>
  <Listbox
    :id="id"
    v-model="selected"
    :class="['w-full max-w-3xl', { 'pointer-events-none': disabled }]"
    as="div"
  >
    <div class="relative">
      <ListboxButton
        class="pt-list-box-control"
        :class="[
          { 'opacity-50': disabled },
          !valid && !disabled && required && selection ? 'border-red' : 'border-gray-soft'
        ]"
      >
        <span
          :class="[
            'block pr-3 truncate',
            disabled
              ? 'text-opacity-60'
              : valid && selected && index != null
              ? 'text-gray-dark '
              : 'text-gray'
          ]"
          v-text="
            valid && selected && index != null
              ? pattern
                ? selected.pattern_title
                : selected[titlePropName]
              : placeholder
          "
        />
        <span
          class="absolute inset-y-0 right-0 flex flex-col items-center justify-center pr-2 pointer-events-none"
        >
          <Icon class="relative top-0.5" name="pt-angle-up" color="gray" aria-hidden="true" />
          <Icon class="relative bottom-0.5" name="pt-angle-down" color="gray" aria-hidden="true" />
        </span>
      </ListboxButton>
      <transition
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <ListboxOptions
          class="absolute z-10 w-full mt-px bg-white shadow-lg max-h-60 rounded-md text-base overflow-auto focus:outline-none sm:text-sm"
        >
          <template v-if="setInputs && setInputs.length">
            <ListboxOption
              v-for="option in setInputs"
              :key="option.id"
              v-slot="{ active }"
              as="template"
              :value="!disabled && setInputs.length === 1 ? setInputs[0] : option"
            >
              <li
                :class="[
                  active ? 'text-white bg-blue-light' : '',
                  'h-10 flex items-center cursor-pointer select-none relative py-2 pl-3 pr-9'
                ]"
                @click="changeValue(option)"
              >
                <span
                  :class="[
                    selected &&
                    (expandParameters
                      ? selected[idPropName] === option.id ||
                        selected[titlePropName] === option[titlePropName]
                      : selected[idPropName] === option.id)
                      ? 'font-semibold'
                      : 'font-normal',
                    'block truncate'
                  ]"
                >
                  {{ pattern ? option.pattern_title : option[titlePropName] }}
                </span>
                <span
                  v-if="
                    selected &&
                    (expandParameters
                      ? selected[idPropName] === option.id ||
                        selected[titlePropName] === option[titlePropName]
                      : selected[idPropName] === option.id)
                  "
                  class="absolute inset-y-0 right-0 flex items-center pr-4 text-blue"
                >
                  <Icon
                    name="pt-check"
                    :color="active ? 'white' : 'blue'"
                    size="1.3"
                    aria-hidden="true"
                  />
                </span>
              </li>
            </ListboxOption>
          </template>
          <div v-else class="flex justify-center p-5">
            <Icon name="pt-spinner" class="block animate-spin" size="1.3" />
          </div>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>
</template>
<script>
import { ref, inject, computed, watch } from 'vue';
import Icon from '@/components/Icon';
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/vue';
export default {
  name: 'PtSelect',
  components: {
    Listbox,
    ListboxButton,
    ListboxOption,
    ListboxOptions,
    Icon
  },
  emits: ['setValue', 'changeValue'],
  setup(_, { emit }) {
    const value = ref('');
    const index = ref(null);
    const valid = ref(false);
    const selected = ref(null);
    const id = inject('id');
    const error = inject('error');
    const inputs = inject('inputs');
    const pattern = inject('pattern');
    const extValue = inject('extValue');
    const required = inject('required');
    const disabled = inject('disabled');
    const selection = inject('selection');
    const propsName = inject('propsName');
    const placeholder = inject('placeholder');
    const expandParameters = inject('expandParameters');
    const idPropName = computed(() => propsName.value.idPropName);
    const titlePropName = computed(() => propsName.value.titlePropName);
    const setInputs = computed(() => {
      if (idPropName.value === 'id' && inputs.value?.some(input => input.id == null)) {
        return inputs.value?.map((input, index) => {
          if (pattern.value) {
            return {
              ...input,
              id: index,
              ['pattern_title']: Array.isArray(pattern.value) ? pattern.value[index] : pattern.value
            };
          } else return { ...input, id: index };
        });
      } else {
        return inputs.value?.map((input, index) => {
          if (pattern.value) {
            return {
              ...input,
              id: input[idPropName.value],
              ['pattern_title']: Array.isArray(pattern.value) ? pattern.value[index] : pattern.value
            };
          } else return { ...input, id: input[idPropName.value] };
        });
      }
    });
    const setIndex = value => {
      if (value && value[titlePropName.value]) {
        index.value = setInputs.value.findIndex(
          input => input[titlePropName.value] === value[titlePropName.value]
        );
      }
    };
    // eslint-disable-next-line complexity
    const setSelect = (option, validity = true, ext = false) => {
      let setterOption;
      setIndex(option);
      if (idPropName.value === 'id' && option?.id == null && !ext) {
        setterOption = pattern.value
          ? {
              ...option,
              ['pattern_title']: Array.isArray(pattern.value)
                ? pattern.value[index.value]
                : pattern.value,
              id: index.value
            }
          : { ...option, id: index.value };
      } else if (!ext) {
        setterOption = pattern.value
          ? {
              ...option,
              ['pattern_title']: Array.isArray(pattern.value)
                ? pattern.value[index.value]
                : pattern.value
            }
          : option;
      } else {
        setterOption = option;
      }
      selection.value = validity;
      valid.value = validity;
      value.value = validity ? setterOption : '';
      selected.value = validity ? setterOption : null;
      emit('setValue', { value: setterOption, validation: validity });
    };
    const changeValue = item => {
      if (String(value.value.id) !== String(item.id)) emit('changeValue', item);
    };
    watch(
      () => selected.value,
      val => {
        if (val) setSelect(val, true, true);
      }
    );
    watch(
      () => setInputs.value,
      // eslint-disable-next-line complexity
      val => {
        if (extValue.value && inputs.value && val.length) {
          if (
            (typeof extValue.value === 'string' ||
              extValue.value instanceof String ||
              (!isNaN(parseFloat(extValue.value)) && !isNaN(extValue.value - 0))) &&
            Array.isArray(val)
          ) {
            setSelect(val.find(input => input[titlePropName.value] === extValue.value));
          } else if (
            typeof extValue.value === 'string' ||
            extValue.value instanceof String ||
            (!isNaN(parseFloat(extValue.value)) && !isNaN(extValue.value - 0))
          ) {
            setSelect({ [titlePropName.value]: extValue.value });
          } else {
            setSelect(extValue.value);
          }
        }
        if (!disabled.value && val?.length === 1) setSelect(val[0]);
      },
      { immediate: true }
    );
    const clearField = () => setSelect(null, false);
    return {
      id,
      index,
      valid,
      error,
      value,
      pattern,
      disabled,
      required,
      selected,
      extValue,
      selection,
      setInputs,
      idPropName,
      clearField,
      changeValue,
      placeholder,
      titlePropName,
      expandParameters
    };
  }
};
</script>
