<template>
  <button
    type="button"
    class="inline-flex items-center justify-center shadow-lg text-base font-medium rounded-md border border-blue-medium bg-white hover:bg-gray-very-light"
  >
    <slot name="content" />
  </button>
</template>
<script>
export default {
  name: 'PtTypeWhite'
};
</script>
