<template>
  <ApexChart :options="chartConfig.options" :series="chartConfig.series" />
</template>

<script>
import { ref, inject } from 'vue';
import ApexChart from 'vue3-apexcharts';
import chart from '@/mixins/chart.js';
export default {
  name: 'PtApexChart',
  components: { ApexChart },
  setup() {
    const chartConfig = ref({});
    const apexProps = inject('apex');
    const globalProps = inject('global');
    const { getChartConfig, initConf } = chart();
    initConf(
      Array.isArray(globalProps.value.data) ? globalProps.value.data : [globalProps.value.data],
      globalProps.value.type,
      apexProps.value.labels,
      Array.isArray(globalProps.value.xaxis) ? globalProps.value.xaxis : [globalProps.value.xaxis],
      Array.isArray(globalProps.value.colors)
        ? globalProps.value.colors
        : [globalProps.value.colors]
    );
    chartConfig.value = getChartConfig();
    return {
      chartConfig
    };
  }
};
</script>
