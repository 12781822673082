export default function () {
  function setElement(element, source, returnKey = false) {
    if (Array.isArray(source)) {
      const searcher = Object.keys(element).filter(elementKey => source?.includes(elementKey));
      return returnKey ? searcher[0] : element[searcher];
    } else {
      return element[source];
    }
  }
  return {
    setElement
  };
}
