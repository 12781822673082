<template>
  <input
    :id="id"
    v-model="value"
    :name="id"
    type="checkbox"
    class="pt-form-checkbox"
    :class="`${!value && !disabled && selection && required ? 'border-red' : 'border-gray-soft'} ${
      disabled ? 'focus:border-gray-soft text-gray-soft' : 'focus:border-blue text-blue'
    }`"
    :disabled="disabled"
  />
</template>
<script>
import { inject, computed } from 'vue';
export default {
  name: 'PtCheck',
  setup() {
    const id = inject('id');
    const extValue = inject('extValue');
    const disabled = inject('disabled');
    const error = inject('error');
    const required = inject('required');
    const selection = inject('selection');
    const valid = true;
    const value = computed({
      get: () => (typeof extValue.value === 'undefined' ? false : extValue.value),
      set: val => {
        extValue.value = val;
      }
    });
    const clearField = () => {
      value.value = false;
      selection.value = false;
    };
    return { id, value, valid, extValue, disabled, error, required, selection, clearField };
  }
};
</script>
