<template>
  <ul
    :class="`flex flex-col lg:flex-row p-4 space-y-2 lg:space-y-0 lg:space-x-2 divide-y lg:divide-y-0 lg:divide-x divide-white bg-${backgroundColor}`"
  >
    <li
      v-for="(info, index) in items"
      :key="index"
      :class="`text-center text-sm lg:text-left ${index === 0 ? '' : 'pt-2 lg:pt-0 lg:pl-2'}`"
    >
      <span class="text-white">{{ info }}</span>
    </li>
  </ul>
</template>
<script>
export default {
  name: 'PtHeadline',
  props: {
    items: {
      type: [Array, Number],
      required: true
    },
    backgroundColor: {
      type: String,
      default: 'blue'
    }
  },
  setup() {}
};
</script>
