<template>
  <div :id="plotlyProps.id" />
</template>

<script>
/* eslint-disable max-nested-callbacks */
/* eslint-disable complexity */
import { ref, inject, onMounted, nextTick, computed } from 'vue';
import Plotly from 'plotly.js-dist-min';
export default {
  name: 'PtChartPlotly',
  setup() {
    const chartConfig = ref({});
    const globalProps = inject('global');
    const plotlyProps = inject('plotly');
    const checkExisting = computed(() => {
      if (plotlyProps.value.existing) {
        const setter = values =>
          values.filter(value => Object.keys(globalProps.value.data[0]).includes(value));
        if (Array.isArray(plotlyProps.value.existing) && plotlyProps.value.existing.length) {
          return setter(plotlyProps.value.existing);
        } else return setter([plotlyProps.value.existing]);
      } else return null;
    });
    chartConfig.value = (() => {
      let data = [];
      let layout = {};
      let config = {};
      if (globalProps.value.data != null && globalProps.value.data.length) {
        const dataValue = [];
        Object.entries(
          Object.fromEntries(
            Object.entries(globalProps.value.data[0]).filter(([k]) => !new RegExp('hided').test(k))
          )
        ).forEach(([k, v]) => {
          dataValue.push({
            ...v,
            data: globalProps.value.data.map((c, i) => ({
              y: c[k].value,
              x: globalProps.value.xaxis[i]
            }))
          });
        });
        const checkDataSpacing = checkExisting.value
          ? [
              ...new Set(
                ...checkExisting.value.map(val => globalProps.value.data.map(r => r[val].value))
              )
            ].filter(Boolean).length
          : true;
        data = dataValue.map(c => {
          nextTick(() => {
            const traces = document.querySelectorAll(`#${plotlyProps.value.id} .traces`);
            traces.forEach((_, ti) => {
              const traceLegend = traces[ti].querySelector('.legendtoggle');
              if (traceLegend) traceLegend.style.display = 'none';
            });
          });
          return {
            name: c.key,
            y: c.data.map(({ y }) => y),
            x: c.data.map(({ x }) => x),
            mode: c.mode.text,
            showlegend: c.showlegend ?? true,
            type: c.type,
            ...(c.annotations && {
              ...(c.annotations.text && { text: c.annotations.text }),
              ...(c.annotations.position && { textposition: c.annotations.position })
            }),
            ...(c.fill?.text && { fill: c.fill.text }),
            ...(c.fill?.color && { fillcolor: c.fill.color }),
            ...(c.yaxis && { yaxis: c.yaxis }),
            ...(c.xaxis && { xaxis: c.xaxis }),
            ...(c.width && { width: c.width }),
            ...(c.hover && {
              ...(c.hover.text && {
                hovertemplate: c.hover.text
              })
            }),
            line: {
              width: 2,
              ...(c.mode?.color && {
                color: c.mode.color
              })
            },
            marker: {
              size: 8,
              symbol: 'circle',
              ...(c.mode?.color && {
                color: c.mode.color
              })
            }
          };
        });
        layout = {
          ...(plotlyProps.value.titles[0] && {
            title: { text: plotlyProps.value.titles[0], y: 0.99, xref: 'paper' }
          }),
          barmode: 'overlay',
          grid: {
            rows: 2,
            roworder: 'bottom to top'
          },
          xaxis: {
            ...(plotlyProps.value.titles[2] && {
              title: plotlyProps.value.titles[2]
            }),
            showgrid: false,
            fixedrange: true
          },
          xaxis2: {
            scaleanchor: 'x',
            visible: false,
            showgrid: false,
            fixedrange: true
          },
          yaxis: {
            ...(plotlyProps.value.titles[3] && {
              title: plotlyProps.value.titles[3]
            }),
            domain: [0, 0.7],
            autorange: 'reversed',
            showgrid: false,
            showline: true,
            linecolor: '#636363',
            linewidth: 2,
            fixedrange: true
          },
          yaxis2: {
            ...(plotlyProps.value.titles[1] && {
              title: plotlyProps.value.titles[1]
            }),
            anchor: 'y',
            rangemode: 'nonnegative',
            autorange: true,
            domain: [checkDataSpacing ? 0.667 : 0.7, 1],
            showgrid: false,
            showline: true,
            linecolor: '#636363',
            linewidth: 2,
            fixedrange: true
          },
          ...(plotlyProps.value.height && { height: plotlyProps.value.height }),
          margin: { t: !plotlyProps.value.static ? 150 : 22 },
          showlegend: !plotlyProps.value.static,
          legend: {
            x: 0,
            y: 1.1,
            yanchor: 'middle',
            orientation: 'h'
          },
          modebar: {
            orientation: 'v'
          },
          autosize: true,
          annotations: [
            {
              xref: 'paper',
              yref: 'paper',
              x: 1,
              xanchor: 'right',
              y: 0,
              xshift: -100,
              yshift: 50,
              yanchor: 'top',
              text: plotlyProps.value.annotation,
              showarrow: false,
              font: {
                size: 16,
                color: '#000000'
              }
            }
          ]
        };
        config = {
          responsive: true,
          scrollZoom: true,
          locale: plotlyProps.value.locale,
          displayModeBar: !plotlyProps.value.static,
          modeBarButtonsToRemove: [
            'zoom',
            'lasso',
            'pan',
            'select',
            'zoomIn',
            'zoomOut',
            'autoScale',
            'reset'
          ],
          toImageButtonOptions: {
            format: 'svg',
            filename: 'grafica',
            height: 500,
            width: 700,
            scale: 1
          }
        };
      }
      return { data, layout, config };
    })();
    onMounted(() => Plotly.newPlot(plotlyProps.value.id, chartConfig.value));
    return {
      plotlyProps
    };
  }
};
</script>
