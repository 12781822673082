<template>
  <TransitionRoot as="template" :show="open">
    <TransitionChild
      as="template"
      enter="ease-out duration-300"
      enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      enter-to="opacity-100 translate-y-0 sm:scale-100"
      leave="ease-in duration-200"
      leave-from="opacity-100 translate-y-0 sm:scale-100"
      leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    >
      <div
        :class="[
          'flex flex-col items-center justify-center space-y-5 px-4 pt-5 pb-4 sm:p-9 text-center overflow-hidden rounded-lg shadow-md transform transition-all mx-auto my-0 bg-white',
          full ? 'w-full' : 'w-auto sm:w-full sm:max-w-xl'
        ]"
      >
        <slot v-if="hasSlot('content')" name="content" />
        <div v-if="hasSlot('closer')" @click="open = false">
          <slot name="closer" />
        </div>
      </div>
    </TransitionChild>
  </TransitionRoot>
</template>

<script>
import slot from '@/mixins/slot';
import { ref } from 'vue';
import { TransitionRoot, TransitionChild } from '@headlessui/vue';
export default {
  name: 'PtPopup',
  components: { TransitionRoot, TransitionChild },
  props: {
    full: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const { hasSlot } = slot();
    const open = ref(true);
    return {
      open,
      hasSlot
    };
  }
};
</script>
<style lang="css">
.pt-popup {
  min-height: 400px;
}
</style>
