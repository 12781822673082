<!--  eslint-disable vue/no-v-html /-->
<template>
  <div
    :class="['w-full flex flex-col overflow-hidden', { shadow: !highlightItem }]"
    :style="width ? `max-width: ${width}px` : undefined"
  >
    <div class="overflow-auto">
      <slot name="tableTitle" />
      <div class="pt-2 align-middle inline-block min-w-full">
        <div
          id="tableElement"
          ref="tableElement"
          class="overflow-y-auto pt-scroll"
          :style="`max-height: ${height}rem`"
        >
          <table class="min-w-full bg-white border-gray-very-light rounded">
            <thead v-if="!horizontal">
              <tr class="sticky top-0" :class="{ 'border-b border-gray-soft': !highlightItem }">
                <th
                  v-for="(header, index) in extHeaders.length === 0 ? headers : extHeaders"
                  :key="index"
                  scope="col"
                  class="px-6 py-3 text-xs font-bold text-gray bg-white tracking-wider"
                >
                  <div
                    v-if="sorting && itemsTable && itemsTable.length > 1"
                    class="relative flex items-center cursor-pointer"
                    :class="isNumberColumn(index) ? 'justify-end' : 'items-center'"
                    @click="sort(Object.keys(itemsTable[0])[index])"
                    @mouseenter="showArrow = index"
                    @mouseleave="showArrow = false"
                  >
                    <span v-html="header"></span>
                    <icon
                      :class="`transition transform ${
                        showArrow === index ? 'opacity-100' : 'opacity-0'
                      }`"
                      :name="`pt-angle-${currentSortDir === 'asc' ? 'down' : 'up'}`"
                    />
                    <icon
                      v-if="editableColumn.firstColumn <= index"
                      class="ml-2 text-lg cursor-pointer"
                      name="pt-edit"
                      @click="editColumn(index)"
                    />
                  </div>
                  <span
                    v-else
                    class="flex"
                    :class="isNumberColumn(index) && extHeaders.length > 2 && 'justify-end'"
                  >
                    <span v-html="header"></span>
                    <icon
                      v-if="editableColumn.firstColumn <= index"
                      class="ml-2 text-lg cursor-pointer"
                      name="pt-edit"
                      @click="editColumn(index)"
                    />
                  </span>
                </th>
                <th
                  v-if="options.length"
                  class="px-6 py-3 text-xs font-bold text-gray bg-white tracking-wider"
                >
                  {{ optionsHeader }}
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(item, index) in setterItems" :key="index">
                <tr v-if="editableColumn.selectorRow !== index" :class="getStyleRow(index)">
                  <td
                    v-for="(info, infoIndex) in Object.keys(item)"
                    :key="infoIndex"
                    class="px-6 py-3 whitespace-nowrap text-sm font-sans text-gray-dark"
                  >
                    <div
                      class="flex flex-col"
                      :class="
                        (isNumberColumn(infoIndex) && !horizontal && extHeaders.length > 2) ||
                        (horizontal && infoIndex !== 0)
                          ? 'items-end'
                          : 'items-start'
                      "
                    >
                      <p
                        v-if="item[info] && item[info].type !== 'link'"
                        :class="getStyleTextElement(item[info])"
                        v-html="item[info].text"
                      ></p>
                      <a
                        v-else-if="item[info] && item[info].type === 'link'"
                        class="text-blue flex items-center space-x-3 hover:underline"
                        target="_blank"
                        :href="item[info].value"
                      >
                        <span>{{ item[info].text }}</span>
                        <Icon name="pt-external-link-alt" class="w-2" color="blue" />
                      </a>
                    </div>
                  </td>
                  <td
                    v-if="options.length"
                    class="px-6 py-3 whitespace-nowrap text-sm font-sans text-gray-dark"
                  >
                    <PtButton
                      v-for="option in options"
                      :key="option.key"
                      size-by-padding="px-4 py-2.5"
                      color="blue"
                      type="bordered"
                      :text="option.label"
                      :icon-name="option.icon"
                      @click="clickedOption(option.key, index)"
                    />
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable max-lines */
import { ref, computed, onMounted, watch, nextTick } from 'vue';
import Icon from '@/components/Icon';
import PtButton from '@/components/Button';
import { helpers } from '@pt/utilities';
import table from '@/mixins/table.js';
export default {
  name: 'PtTable',
  components: {
    Icon,
    PtButton
  },
  props: {
    items: {
      type: [Array, Object],
      default: () => []
    },
    extHeaders: {
      type: Array,
      default: () => []
    },
    sorting: {
      type: Boolean,
      default: false
    },
    height: {
      type: [String, Number],
      default: '24',
      validator: val => !isNaN(+val)
    },
    width: {
      type: [String, Number, Boolean],
      default: '1024',
      validator: val => !isNaN(+val) || !!val === val
    },
    scrollTo: {
      type: Number,
      default: undefined
    },
    nameIndex: {
      type: String,
      default: undefined
    },
    editableColumn: {
      type: Object,
      default: () => ({
        firstColumn: undefined,
        selectorRow: undefined
      })
    },
    options: {
      type: Array,
      default: () => []
    },
    optionsHeader: {
      type: String,
      default: 'Opciones'
    },
    highlightItem: {
      type: String,
      default: ''
    },
    horizontal: {
      type: Boolean,
      default: false
    },
    enabledNumberAlign: {
      type: Boolean,
      default: true
    }
  },
  emits: ['editColumn', 'clickedOption'],
  setup(props, { emit }) {
    const { formatHorizontalTable } = table();
    const headers = ref([]);
    const itemsTable = ref([]);
    const currentSort = ref('');
    const showArrow = ref(false);
    const tableElement = ref(null);
    const currentSortDir = ref('asc');
    const indexHeaderNumberColum = ref([]);
    const setterItems = computed(() => {
      if (props.sorting) {
        return [...itemsTable.value].sort((a, b) => {
          let modifier = 1;
          if (currentSortDir.value === 'desc') modifier = -1;
          if (a[currentSort.value] < b[currentSort.value]) return -1 * modifier;
          if (a[currentSort.value] > b[currentSort.value]) return 1 * modifier;
        });
      } else return itemsTable.value;
    });
    const sort = header => {
      if (header === currentSort.value) {
        currentSortDir.value = currentSortDir.value === 'asc' ? 'desc' : 'asc';
      }
      currentSort.value = header;
    };
    if (props.extHeaders.length === 0) {
      const itemKeys = props.items.find(
        obj =>
          Object.keys(obj).length >=
          Math.max.apply(
            Math,
            props.items.map(item => Object.keys(item).length)
          )
      );
      headers.value = itemKeys !== undefined ? Object.keys(itemKeys) : [];
    }
    const getStyleTextElement = item => {
      let style = item?.isBold ? 'font-bold' : '';
      style += item?.color !== undefined ? 'text-' + item.color : '';
      return style;
    };
    const getStyleRow = index => {
      let style = index !== 0 ? 'border-t border-gray-very-light' : '';
      style += index === props.scrollTo ? ' scrollHere' : '';
      style += index === parseInt(props.highlightItem) ? ' bg-blue-very-light' : '';
      return style;
    };
    const isNumber = (value, index) => {
      if (
        value &&
        typeof value === 'string' &&
        !isNaN(
          value
            .replace(',', '')
            .replace('%', '')
            .replace(/(<([^>]+)>)/gi, '')
        )
      ) {
        indexHeaderNumberColum.value.push(index);
        return true;
      }
      return false;
    };
    const formatedNumbersItems = () => {
      let items = helpers.formatNumberValues(itemsTable.value, props.nameIndex);
      if (items.constructor === Array) {
        items.forEach(item => {
          Object.keys(item).forEach((e, index) => {
            if (typeof item[e] === 'object' && item[e]) {
              props.nameIndex !== e ? isNumber(item[e].text, index) : false;
            } else {
              let setterItem = item;
              if (typeof item[e] === 'boolean') {
                setterItem[e] = item[e];
              } else {
                setterItem[e] = {
                  text: item[e],
                  isNumber: props.nameIndex !== e ? isNumber(item[e], index) : false
                };
              }
              return setterItem;
            }
          });
        });
      }
      itemsTable.value = items;
    };
    const clickedOption = (key, id) => emit('clickedOption', { key, id });
    const isNumberColumn = index =>
      indexHeaderNumberColum.value.some(e => e === index) && props.enabledNumberAlign;
    const editColumn = index => {
      const obj = props.items[props.editableColumn.selectorRow];
      const columnId = obj[Object.keys(obj)[index]];
      emit('editColumn', columnId);
    };
    const replacesWithDash = () => {
      const items = JSON.parse(JSON.stringify(props.items));
      if (items.constructor === Array) {
        items.forEach(o => {
          Object.keys(o).forEach(prop => {
            if (o[prop] === -999) {
              /*eslint no-param-reassign: "error"*/
              o[prop] = '-';
            }
          });
        });
      } else if (typeof items === 'object') {
        Object.keys(items).forEach(prop => {
          if (items[prop] === -999) items[prop] = '-';
        });
      }
      itemsTable.value = items;
    };
    onMounted(() => {
      replacesWithDash();
      formatedNumbersItems();
      if (props.horizontal)
        itemsTable.value = formatHorizontalTable(itemsTable.value, props.extHeaders);
      if (props.scrollTo) {
        nextTick(() => {
          tableElement.value.scrollTop = 0;
          tableElement.value.scrollTop = props.scrollTo * 45;
        });
      }
    });
    watch(
      () => props.items,
      () => {
        indexHeaderNumberColum.value = [];
        replacesWithDash();
        formatedNumbersItems();
      }
    );
    return {
      sort,
      headers,
      isNumber,
      showArrow,
      itemsTable,
      editColumn,
      setterItems,
      getStyleRow,
      currentSort,
      tableElement,
      clickedOption,
      isNumberColumn,
      currentSortDir,
      getStyleTextElement
    };
  }
};
</script>
