<template>
  <div class="flex flex-col">
    <div class="relative cursor-pointer" @click="modal = true">
      <div
        :class="[
          'absolute w-full flex items-end bottom-0 p-2.5 md:p-5',
          setterImages.data.title || setterImages.data.author ? 'justify-between' : 'justify-end'
        ]"
      >
        <div class="flex flex-wrap items-center -my-1 -ml-1 mr-1">
          <span
            v-if="setterImages.data.title"
            class="m-1 py-0.5 px-1 text-xs font-semibold rounded truncate bg-gray text-white"
            style="max-width: 14rem"
            v-text="setterImages.data.title"
          />
          <span
            v-if="setterImages.data.author"
            class="m-1 py-0.5 px-1 text-xs font-semibold rounded truncate bg-gray text-white"
            style="max-width: 14rem"
            v-text="setterImages.data.author"
          />
        </div>
        <span
          class="py-0.5 px-1 text-xs font-semibold rounded bg-gray text-white"
          v-text="`${current.number + 1}/${setterImages.head.length}`"
        />
      </div>
      <img
        v-for="image in setterImages.head"
        v-show="image.key === current.key"
        :key="image.key"
        class="image h-full w-full flex-auto object-cover"
        :src="image.src"
      />
    </div>
    <template v-if="images.length > 1">
      <transition
        enter-active-class="duration-300 ease-out"
        enter-from-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="duration-200 ease-in"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div
          v-if="images.length > 1"
          class="w-full flex mt-5 space-x-5 overflow-x-auto overflow-y-hidden hide-scroll"
        >
          <div
            v-for="image in setterImages.carousel"
            :key="image.key"
            class="relative"
            style="flex: 0 0 10rem"
          >
            <img
              class="w-full h-24 object-cover object-center transition hover:shadow-lg cursor-pointer"
              :src="image.src"
              @click="changeImage(image.key)"
            />
          </div>
        </div>
      </transition>
    </template>
    <Modal v-model:open="modal" show-close-icon max-width="2xl">
      <template #content>
        <img class="image w-full h-full" :src="setterImages.selected.src" />
      </template>
    </Modal>
  </div>
</template>
<script>
import Modal from '@/components/Modal';
import { reactive, toRefs, computed } from 'vue';
export default {
  name: 'PtGallery',
  components: { Modal },
  props: {
    images: {
      type: Array,
      required: true
    },
    urlPropName: {
      type: String,
      default: 'url'
    },
    titlePropName: {
      type: String,
      default: 'title'
    },
    authorPropName: {
      type: String,
      default: 'author'
    }
  },
  setup(props) {
    const state = reactive({
      modal: false,
      current: {
        number: 0,
        key: 'image_0',
        selected: null
      }
    });
    const { modal, current } = toRefs(state);
    const setterImages = computed(() => {
      const head = props.images.map((image, index) => ({
        ...(Object.keys(image).length && { ...image }),
        src: image[props.urlPropName] || image || '',
        key: `image_${index}`
      }));
      const selected = current.value.selected ?? head[0];
      return {
        head,
        selected,
        data: {
          title: selected[props.titlePropName] || null,
          author: selected[props.authorPropName] || null
        },
        carousel: head.filter((_, index) => current.value.number !== index)
      };
    });
    const changeImage = image => {
      const index = setterImages.value.head.map(({ key }) => key).indexOf(image);
      current.value.key = image;
      current.value.number = index;
      current.value.selected = setterImages.value.head[index];
    };
    return {
      modal,
      current,
      changeImage,
      setterImages
    };
  }
};
</script>
<style scoped>
.image {
  aspect-ratio: 16 / 9;
}
</style>
