<template>
  <div class="h-full max-w-3xl flex flex-col flex-grow overflow-y-auto">
    <template v-if="hasSlot('header')">
      <slot name="header" />
    </template>
    <template v-if="hasSlot('menu')">
      <slot name="menu" />
      <hr role="separator" aria-orientation="horizontal" class="mt-2 border-gray-very-light" />
    </template>
    <div class="flex">
      <template v-if="hasSlot('breadcrumbs')">
        <slot name="breadcrumbs" />
      </template>
      <SidebarNav v-else :class-nav="classNav" @click-item="clickItem" />
    </div>
  </div>
</template>

<script>
import slot from '@/mixins/slot';
import SidebarNav from './SidebarNav';
export default {
  name: 'PtSidebar',
  components: { SidebarNav },
  provide() {
    return {
      href: this.href,
      icon: this.icon,
      name: this.name,
      items: this.items,
      value: this.value,
      isMobile: this.isMobile,
      hideHover: this.hideHover,
      standItem: this.standItem,
      hideRoute: this.hideRoute
    };
  },
  props: {
    items: {
      type: [Array, Object],
      required: true
    },
    isMobile: {
      type: Boolean,
      default: false
    },
    standItem: {
      type: Object,
      default: undefined
    },
    route: {
      type: Object,
      default: undefined
    },
    value: {
      type: [String, Array],
      default: 'value'
    },
    href: {
      type: [String, Array],
      default: 'href'
    },
    icon: {
      type: [String, Array],
      default: 'icon'
    },
    name: {
      type: [String, Array],
      default: 'name'
    },
    classNav: {
      type: String,
      default: ''
    },
    hideHover: {
      type: Boolean,
      default: false
    },
    hideRoute: {
      type: Boolean,
      default: false
    }
  },
  emits: ['clickItem'],
  setup(_, { emit }) {
    const { hasSlot } = slot();
    const clickItem = item => emit('clickItem', item);
    return {
      hasSlot,
      clickItem
    };
  }
};
</script>
