<template>
  <component :is="type" @[ev]="setSearch">
    <template
      v-if="type === 'navigator' && hasSlot('content')"
      #default="{ exportItems, exportPage }"
    >
      <slot name="content" :get-items="exportItems" :get-page="exportPage" />
    </template>
  </component>
</template>
<script>
/* eslint-disable complexity */
/* eslint-disable no-underscore-dangle */
import { reactive, toRefs, computed, watch } from 'vue';
import { MeiliSearch } from 'meilisearch';
import { env } from '@pt/env-config';
import slot from '@/mixins/slot';
import Box from './Box';
import Navigator from './Navigator';
export default {
  name: 'PtSearch',
  components: { Box, Navigator },
  provide() {
    return {
      box: computed(() => ({
        search: this.search,
        local: this.local,
        placeholder: this.placeholder,
        focus: this.focus,
        maximum: this.setterMaximum(this),
        category: this.category
      })),
      navigator: computed(() => ({
        items: this.items ?? this.storageItems,
        limit: this.limit,
        paginator: this.paginator,
        placeholder: this.placeholder,
        processed: this.mixedWaiters,
        filters: this.filters
      }))
    };
  },
  props: {
    type: {
      type: String,
      default: 'box',
      validator: val => /box|navigator/.test(val)
    },
    source: {
      type: String,
      default: 'stratos',
      validator: val => /stratos|strapi/.test(val)
    },
    search: {
      type: String,
      default: ''
    },
    local: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: 'Buscador'
    },
    focus: {
      type: Boolean,
      default: false
    },
    formatted: {
      type: Boolean,
      default: false
    },
    processed: {
      type: Boolean,
      default: false
    },
    category: {
      type: String,
      default: ''
    },
    items: {
      type: Array,
      default: undefined
    },
    paginator: {
      type: Boolean,
      default: false
    },
    maximum: {
      type: [Boolean, Number, String],
      default: 999,
      validator: val => !!val === val || !isNaN(+val)
    },
    limit: {
      type: [Number, String],
      default: 6,
      validator: val => !isNaN(+val)
    },
    filters: {
      type: [Array, String, Boolean, Object],
      default: ''
    },
    highlighted: {
      type: [Boolean, Array, String],
      default: false
    },
    retrieved: {
      type: [Boolean, Array, String],
      default: false
    }
  },
  emits: ['update:search', 'getItems'],
  setup(props, { emit }) {
    const { hasSlot } = slot();
    const state = reactive({
      storageItems: [],
      processedItems: false,
      processedSearch: false
    });
    const { storageItems, processedItems, processedSearch } = toRefs(state);
    const ev = computed(() => (props.type === 'box' ? 'setSearch' : null));
    const checkFilterType = computed(
      () =>
        typeof props.filters === 'object' && !Array.isArray(props.filters) && props.filters != null
    );
    const setterCategory = computed(() => {
      if (props.category) {
        return props.category.includes(':') ? props.category.split(':')[0] : props.category;
      } else {
        return false;
      }
    });
    const setterMaximum = (source = props) => {
      if (source.maximum === true) return 9999999;
      else if (source.maximum === false) return 20;
      else if (!isNaN(+source.maximum)) return +source.maximum;
      else return 999;
    };
    const getItems = async () => {
      if (setterCategory.value && !props.local) {
        processedSearch.value = false;
        const client = new MeiliSearch({
          host: env.APP_MEILISEARCH_HOST,
          apiKey: env.APP_MEILISEARCH_API_KEY
        }).index(setterCategory.value);
        return await client
          .search(props.search, {
            ...(!props.search && { limit: setterMaximum() }),
            ...(props.category.includes(':') && {
              sort: [props.category.substring(props.category.indexOf(':') + 1)]
            }),
            ...(checkFilterType.value &&
              processedItems.value &&
              /filter/.test(Object.keys(props.filters)) &&
              props.filters.filter.length && {
                filter: Array.isArray(props.filters.filter)
                  ? props.filters.filter
                  : [props.filters.filter]
              }),
            ...(checkFilterType.value &&
              processedItems.value &&
              /facetsDistribution/.test(Object.keys(props.filters)) &&
              props.filters.facetsDistribution.length && {
                facetsDistribution: Array.isArray(props.filters.facetsDistribution)
                  ? props.filters.facetsDistribution
                  : [props.filters.facetsDistribution]
              }),
            ...(props.highlighted &&
              props.search && {
                attributesToHighlight:
                  !!props.highlighted === props.highlighted
                    ? ['*']
                    : Array.isArray(props.highlighted)
                    ? props.highlighted
                    : [props.highlighted]
              }),
            ...(props.retrieved && {
              attributesToRetrieve:
                !!props.retrieved === props.retrieved
                  ? ['*']
                  : Array.isArray(props.retrieved)
                  ? props.retrieved
                  : [props.retrieved]
            })
          })
          .then(({ hits }) => {
            storageItems.value = hits;
            emit(
              'getItems',
              props.formatted
                ? props.highlighted && props.search
                  ? hits.map(hit => hit._formatted)
                  : hits
                : hits
            );
            processedItems.value = true;
          })
          .finally(() => (processedSearch.value = true));
      }
    };
    const mixedWaiters = computed(() => processedSearch.value && !props.processed);
    const setSearch = search => emit('update:search', search);
    const watcherFilter = computed(
      () => /filter/.test(Object.keys(props.filters)) && props.filters.filter
    );
    watch(
      () => [watcherFilter.value, props.search],
      () => getItems(),
      { deep: true, immediate: true }
    );
    return {
      ev,
      hasSlot,
      getItems,
      setSearch,
      storageItems,
      mixedWaiters,
      setterMaximum
    };
  }
};
</script>
