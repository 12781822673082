<template>
  <div class="pt-labels" :class="{ 'pt-separator': separator }">
    <template v-if="Array.isArray(items)">
      <div
        v-for="item in items"
        :key="item.label"
        class="pt-labels__item"
        :class="`${
          'pt-labels__item--' +
          labelType +
          ' font-' +
          weightType +
          ' text-' +
          textSize +
          ' font-' +
          fontType
        } ${toSearch ? 'clickable' : ''}`"
        @[ev]="setFilter"
        v-text="item.label || item.date || item"
      />
    </template>
    <template v-else-if="typeof items === 'object' && items !== null">
      <div
        class="pt-labels__item"
        :class="`${
          'pt-labels__item--' +
          labelType +
          ' font-' +
          weightType +
          ' text-' +
          textSize +
          ' font-' +
          fontType
        } ${toSearch ? 'clickable' : ''}`"
        @[ev]="setFilter"
        v-text="items.label"
      />
    </template>
    <template v-else>
      <div
        v-if="items === 'Hibrido' || items === 'Hybrid' || items === 'Onsite'"
        class="pt-labels__item"
        :class="`${
          'pt-labels__item--' +
          labelType +
          ' font-' +
          weightType +
          ' text-' +
          textSize +
          ' font-' +
          fontType
        } ${toSearch ? 'clickable' : ''}`"
        @[ev]="setFilter"
        v-text="$t('calendar.presential')"
      />
      <div
        v-if="items === 'Hibrido' || items === 'Hybrid'"
        class="pt-labels__item"
        :class="`${
          'pt-labels__item--' +
          labelType +
          ' font-' +
          weightType +
          ' text-' +
          textSize +
          ' font-' +
          fontType
        } ${toSearch ? 'clickable' : ''}`"
        @[ev]="setFilter"
        v-text="$t('calendar.web')"
      />
      <div
        v-else
        class="pt-labels__item"
        :class="`${
          'pt-labels__item--' +
          labelType +
          ' font-' +
          weightType +
          ' text-' +
          textSize +
          ' font-' +
          fontType
        } ${toSearch ? 'clickable' : ''}`"
        @[ev]="setFilter"
        v-text="items"
      />
    </template>
  </div>
</template>

<script>
import { enums } from '@pt/env-config';
import store from '../../store';

export default {
  name: 'PtLabels',
  props: {
    items: {
      type: [Number, Array, Object, String],
      default: () => ''
    },
    separator: {
      type: Boolean,
      default: false
    },
    labelType: {
      type: String,
      default: 'arable',
      validator: value =>
        value.match(
          /(arable|blue|gray|blue-light|gray-light|expired|red|transparent|white|orange|text-gray|earth|orange-bg|black|text-gray-dark|text-gray-light)/
        )
    },
    weightType: {
      type: String,
      default: 'semibold',
      validator: value => value.match(/(extralight|light|normal|medium|semibold|bold|ubuntu)/)
    },
    textSize: {
      type: String,
      default: 'sm',
      validator: value => value.match(/(xs|sm|base|lg|xl|2xl|3xl|4xl)/)
    },
    fontType: {
      type: String,
      default: 'sans',
      validator: value => value.match(/(sans|ubuntu)/)
    },
    toSearch: {
      type: [Boolean, String],
      default: false
    },
    spaceUrl: { type: String, default: '' },
    ev: {
      type: String,
      default: 'click',
      validator: value => value.match(/(click|mouseup)/)
    }
  },
  computed: {
    filter() {
      return store.getters.getByField('filter');
    }
  },
  methods: {
    setFilter(filterValue) {
      filterValue.preventDefault();
      const filterText = [
        filterValue.target.textContent.toLowerCase(),
        filterValue.target.innerHTML.toLowerCase(),
        filterValue.target.outerText.toLowerCase()
      ];
      const filterTest = new RegExp(filterText.join('|')).test(this.filter);
      if (this.toSearch && (this.$route.path === enums.SEARCH_RESULTS_URL ? !filterTest : true)) {
        if (typeof this.toSearch === 'string') {
          store.commit('setFilter', filterText);
          if (this.$route.path !== enums.SEARCH_RESULTS_URL) {
            this.scrollToSmoothly(0);
          }
        } else {
          this.scrollToSmoothly(0);
        }
        if (this.$route.path !== enums.SEARCH_RESULTS_URL) {
          window.location.href = `${this.spaceUrl}${enums.SEARCH_RESULTS_URL}#soo`;
        }
        this.scrollToSmoothly(0);
      }
    },
    scrollToSmoothly(position) {
      window.scrollTo({
        top: position,
        behavior: 'smooth'
      });
    }
  }
};
</script>

<style scoped>
.pt-labels {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.pt-labels__item {
  min-height: 1rem;
  margin-left: 0.375rem;
  margin-right: 0.375rem;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.pt-labels__item.clickable {
  cursor: pointer;
}
.pt-labels__item.clickable:hover {
  opacity: 0.8;
  text-decoration: underline;
}
.pt-labels__item--blue {
  background-color: blue;
  color: white;
  border-radius: 0.125rem;
}
.pt-labels__item--blue-light {
  background-color: lightblue;
  color: white;
  border-radius: 0.125rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.pt-labels__item--blue-bg-white {
  background-color: white;
  color: rgb(16 157 194 / var(--tw-text-opacity, 1));
  border-radius: 0.125rem;
}
.pt-labels__item--gray {
  background-color: gray;
  color: white;
  border-radius: 0.125rem;
}
.pt-labels__item--gray-bg {
  background-color: gray;
  color: white;
  text-transform: none;
  border-radius: 0.125rem;
}
.pt-labels__item--gray-light {
  background-color: lightgray;
  color: gray;
  border-radius: 0.125rem;
}
.pt-labels__item--red {
  background-color: red;
  color: white;
  border-radius: 0.125rem;
}
.pt-labels__item--expired {
  background-color: lightgray;
  color: red;
  font-weight: 600;
}
.pt-labels__item--transparent {
  color: rgb(16 157 194 / var(--tw-text-opacity, 1));
  text-transform: none;
  padding-left: 0;
  padding-right: 0;
}
.pt-labels__item--white {
  background-color: lightgray;
  color: gray;
  border-radius: 0.125rem;
}
.pt-labels__item--orange {
  color: orange;
  text-transform: none;
  padding-left: 0;
  padding-right: 0;
}
.pt-labels__item--orange-bg {
  background-color: orange;
  color: white;
  text-transform: none;
  border-radius: 0.125rem;
}
.pt-labels__item--earth {
  color: darkorange;
  text-transform: none;
  padding-left: 0;
  padding-right: 0;
}
.pt-labels__item--arable {
  color: darkorange;
  text-transform: none;
  padding-left: 0;
  padding-right: 0;
}
.pt-labels__item--text-gray {
  color: gray;
  text-transform: none;
  padding-left: 0;
  padding-right: 0;
}
.pt-labels__item--text-gray-dark {
  color: darkgray;
  text-transform: none;
  padding-left: 0;
  padding-right: 0;
}
.pt-labels__item--text-gray-light {
  color: lightgray;
  text-transform: none;
  padding-left: 0;
  padding-right: 0;
}
.pt-labels__item--black {
  color: black;
}
.pt-separator {
  padding-left: 0;
  padding-right: 0;
  border-left: 1px solid lightgray;
}
.pt-separator .pt-labels__item:not(:first-child) {
  padding-left: 0.75rem;
}
</style>
