<template>
  <div
    class="pt-course-megamenu w-full bg-cover bg-center relative h-full"
    :style="{
      backgroundImage: `url(${cardInfo?.main_image.url})`
    }"
  >
    <div class="gradient-overlay absolute inset-0"></div>
    <div class="flex flex-col relative z-10">
      <div class="w-full relative" :style="{ minHeight: '170px' }">
        <span
          v-if="!dataExpired(cardInfo?.published_date)"
          class="pt-add absolute top-2 left-2 uppercase"
        >
          {{ $t('recentlyAdded') }}
        </span>
        <span class="pt-caduca absolute bottom-2 left-4">
          {{
            `${$t('inscriptionExpired')}${new Date(cardInfo?.finish_date)
              .toLocaleDateString('es-ES', { day: 'numeric', month: 'long' })
              .toUpperCase()}`
          }}
        </span>
      </div>
      <div class="flex flex-col w-full px-4 py-2">
        <div class="border-b border-gray pb-2 flex flex-col">
          <Labels
            v-if="cardInfo?.tags?.length"
            label-type="transparent"
            :items="cardInfo?.tags"
            :space-url="spaceUrl"
            to-search
          />
          <a
            :href="`${spaceUrl}${FORMATION_COURSE_BASE_URL}/${cardInfo?.slug}#sso`"
            class="pt-titleCourse text-white cursor-pointer line-clamp-3 hover:underline text-mobile"
          >
            {{ cardInfo?.title }}
          </a>
        </div>
        <div class="flex justify-between w-full pt-2">
          <span class="text-white pt-cardFooterCourse">
            <PtIcon name="pt-signal-alt-3" color="white" class="text-sm" />
            {{ firstLetterUppercase($t(`strapiDataMap.${cardInfo.course_features.course_level}`)) }}
          </span>
          <span v-if="totalDuration > 0" class="text-white pt-cardFooterCourse">
            <PtIcon name="pt-clock" color="white" class="text-sm" />
            {{ `${Math.floor(totalDuration / 60)} h ${totalDuration % 60} min` }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { helpers } from '@pt/utilities';
import { enums } from '@pt/env-config';
import { computed } from 'vue';
import Labels from '@/components/Labels';
import PtIcon from '../../Icon';
export default {
  name: 'CardCourseMegamenu',
  components: { PtIcon, Labels },
  props: {
    cardInfo: {
      type: Object,
      default: () => ({})
    },
    spaceUrl: { type: String, default: '' }
  },
  setup(props) {
    const firstLetterUppercase = helpers.firstLetterUppercase;
    const { FORMATION_COURSE_BASE_URL } = enums;
    const dataExpired = dateSend => {
      const currentDate = new Date();
      const sendDate = new Date(dateSend);
      const diffTime = Math.abs(currentDate - sendDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return diffDays >= 8;
    };
    const totalDuration = computed(() => {
      let duration = 0;
      props.cardInfo?.blocks.forEach(({ items }) => {
        items.forEach(item => {
          duration += item.duration;
        });
      });
      return duration !== 0 ? duration : false;
    });

    return {
      FORMATION_COURSE_BASE_URL,
      firstLetterUppercase,
      totalDuration,
      dataExpired
    };
  }
};
</script>

<style scoped lang="scss">
.pt-course-megamenu {
  position: relative;
  width: 100%;
  border-radius: 0.5rem;
  border: 4px solid rgb(0, 204, 255);

  .gradient-overlay {
    position: absolute;
    z-index: 1;
    inset: 0;
    border-radius: 0.5rem;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 1));
  }
}

.pt-add {
  background-color: rgb(0, 204, 255);
  font-size: 0.875rem;
  color: white;
  padding: 0.25rem 0.5rem;
  font-family: sans-serif;
  font-weight: 600;
  border-radius: 0.25rem;
}

.pt-caduca {
  background-color: #f5f5f5;
  font-size: 12px;
  color: rgb(194 57 52 / var(--tw-text-opacity, 1));
  padding: 4px;
  font-family: sans-serif;
  font-weight: 600;
  border-radius: 0.25rem;
}

.pt-titleCourse {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 700;
  line-height: 1.25rem;
  color: white;
  position: relative;
  z-index: 10;
}

.pt-subtitleCourse {
  font-size: 0.75rem;
  line-height: 1.094rem;
  position: relative;
  z-index: 10;
}

.pt-cardFooterCourse {
  font-size: 12px;
  line-height: 1.094rem;
  position: relative;
  z-index: 10;
  font-family: sans-serif;
  font-weight: 600;
}
@media (max-width: 1023px) {
  .text-mobile {
    font-size: 1.125rem /* 18px */;
    line-height: 1.75rem /* 28px */;
  }
}
</style>
