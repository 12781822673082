<template>
  <div
    :class="`w-full h-full flex justify-center items-center left-0 top-0 z-50 ${
      external ? 'fixed' : 'absolute'
    } ${showWrapper && 'bg-loading'}`"
  >
    <svg
      :class="`animate-spin h-${heightWidth} w-${heightWidth} text-${color}`"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4" />
      <path
        class="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      />
    </svg>
  </div>
</template>
<script>
export default {
  name: 'PtLoading',
  props: {
    color: {
      type: String,
      default: 'blue'
    },
    showWrapper: {
      type: Boolean,
      default: true
    },
    heightWidth: {
      type: String,
      default: '16'
    },
    external: {
      type: Boolean,
      default: false
    }
  }
};
</script>
