/* eslint-disable complexity */
<template>
  <input
    v-if="disabled"
    :id="id"
    :type="inputType"
    :name="id"
    readonly
    class="pt-form-control opacity-40 border-gray-soft pointer-events-none"
    :value="extValue"
    :placeholder="placeholder"
  />
  <input
    v-else
    :id="id"
    v-model="extValue"
    v-appear="inputType === 'number'"
    :type="inputType === 'number' ? 'text' : inputType"
    :name="id"
    :class="[
      'pt-form-control',
      value ? 'text-gray-dark' : 'text-gray',
      !valid && !disabled && selection && (required || value.toString().length > 0)
        ? 'border-red'
        : 'border-gray-soft'
    ]"
    :placeholder="placeholder"
    :min="/number|date/.test(inputType) && min ? min : null"
    :max="/number|date/.test(inputType) && max ? max : null"
    @input="setValue"
    @[ev.focusNumber]="cleanValue"
    @[ev.blurNumber]="releaseValue"
    @[ev.pasteNumber]="preventPastedLetters"
    @[ev.keydownNumber]="preventWrittenLetters"
    @blur.capture="inputType === 'number' ? releaseValue($event) : null"
    @click.once="inputType === 'number' ? releaseValue($event) : null"
  />
</template>
<script>
import { ref, inject, computed, watch } from 'vue';
export default {
  name: 'PtInput',
  directives: {
    appear: {
      mounted(el, binding) {
        if (binding.value) el.click();
      }
    }
  },
  emits: ['setValue'],
  setup(_, { emit }) {
    const inputType = inject('inputType');
    const placeholder = inject('placeholder');
    const id = inject('id');
    const selection = inject('selection');
    const disabled = inject('disabled');
    const error = inject('error');
    const required = inject('required');
    const numberSettings = inject('numberSettings');
    const extValue = inject('extValue');
    const pattern = inject('pattern');
    const value = ref('');
    const valid = ref(false);
    const modified = ref(false);
    const decimals = numberSettings.value.decimals;
    let min = numberSettings.value.minValue;
    let max = numberSettings.value.maxValue;
    const setValue = (e, ext = false) => {
      const targetValue = ext ? e : e.target.value;
      const defaultValid =
        inputType.value === 'number'
          ? targetValue.toString().replace(/[^\w\s]/gi, '').length > 0
          : targetValue.toString().length > 0;
      // eslint-disable-next-line complexity
      const setterValid = () => {
        if (inputType.value === 'number' && (min || max)) {
          const numberTargetValue = (+targetValue
            .toString()
            .replace(/,/g, '.')
            .replace(/\.(?=[^.]+$)/, '*')
            .replace(/\./g, '')
            .replace(/\*/g, '.')).toFixed(2);
          if (pattern.value != null) {
            if (min && !max) return numberTargetValue >= +min && pattern.value;
            else if (!min && max) return numberTargetValue <= +max && pattern.value;
            else return numberTargetValue >= +min && numberTargetValue <= +max && pattern.value;
          } else {
            if (min && !max) return numberTargetValue >= +min;
            else if (!min && max) return numberTargetValue <= +max;
            else return numberTargetValue >= +min && numberTargetValue <= +max;
          }
        } else if (inputType.value === 'email') {
          return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(targetValue);
        } else if (pattern.value != null) {
          return pattern.value;
        } else {
          return true;
        }
      };
      const setterErrors = computed(() =>
        Array.isArray(error.value)
          ? pattern.value != null && defaultValid
            ? error.value.length === 3
              ? error.value[2]
              : error.value[1]
            : defaultValid
            ? error.value[1]
            : error.value[0]
          : error.value
      );
      if (ext ? defaultValid : true) {
        selection.value = true;
      }
      value.value = targetValue;
      valid.value = setterValid() && defaultValid;
      emit('setValue', {
        value: targetValue,
        validation: setterValid() && defaultValid,
        errors: setterErrors.value
      });
    };
    watch(
      () => numberSettings.value.minValue,
      val => {
        min = val;
        setValue(value.value, true);
      }
    );
    watch(
      () => numberSettings.value.maxValue,
      val => {
        max = val;
        setValue(value.value, true);
      }
    );
    const ev = computed(() => ({
      focusNumber: inputType.value === 'number' ? 'focus' : null,
      blurNumber: inputType.value === 'number' ? 'blur' : null,
      keydownNumber: inputType.value === 'number' ? 'keydown' : null,
      pasteNumber: inputType.value === 'number' ? 'paste' : null,
      clickNumber: inputType.value === 'number' ? 'click' : null
    }));
    // eslint-disable-next-line complexity
    const releaseValue = e => {
      if (e.target.value) {
        const thousandDot = num =>
          num
            .toString()
            .replace(/\./g, '')
            .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        if (!modified.value) {
          const replacer = e.target.value.replace('.', ',');
          if (decimals) {
            e.target.value = thousandDot(replacer);
          } else {
            if (e.target.value.includes('.')) {
              if (e.target.value.split('.')[1].length === 0) {
                e.target.value = thousandDot(e.target.value.split('.')[0]).replace(',', '');
              } else if (e.target.value.split('.')[1].length === 1) {
                e.target.value = `${thousandDot(e.target.value.split('.')[0])},${
                  e.target.value.split('.')[1]
                }0`;
              } else if (e.target.value.split('.')[1].length > 1) {
                e.target.value = `${thousandDot(e.target.value.split('.')[0])},${e.target.value
                  .split('.')[1]
                  .substring(0, 2)}`;
              } else {
                e.target.value = thousandDot(e.target.value);
              }
            }
          }
          modified.value = true;
        }
        if (decimals) {
          if (!thousandDot(e.target.value).includes(',')) {
            e.target.value = `${thousandDot(e.target.value)},00`;
          } else if (
            thousandDot(e.target.value).includes(',') &&
            thousandDot(e.target.value).split(',')[1].length === 0
          ) {
            e.target.value = `${thousandDot(e.target.value.split(',')[0])},00`;
          } else if (
            thousandDot(e.target.value).includes(',') &&
            thousandDot(e.target.value).split(',')[1].length === 1
          ) {
            e.target.value = `${thousandDot(e.target.value.split(',')[0])},${
              e.target.value.split(',')[1]
            }0`;
          } else if (
            thousandDot(e.target.value).includes(',') &&
            thousandDot(e.target.value).split(',')[1].length > 1
          ) {
            e.target.value = `${thousandDot(e.target.value.split(',')[0])},${e.target.value
              .split(',')[1]
              .substring(0, 2)}`;
          }
          value.value = e.target.value
            .replace(/,(?=[^,]+$)/, '*')
            .replace(/\./g, '')
            .replace(/\*/g, '.');
        } else {
          if (thousandDot(e.target.value).includes(',')) {
            if (thousandDot(e.target.value).split(',')[1].length === 0) {
              e.target.value = thousandDot(e.target.value).replace(',', '');
            } else if (thousandDot(e.target.value).split(',')[1].length === 1) {
              e.target.value = `${thousandDot(e.target.value.split(',')[0])},${
                e.target.value.split(',')[1]
              }0`;
            } else if (thousandDot(e.target.value).split(',')[1].length > 1) {
              e.target.value = `${thousandDot(e.target.value.split(',')[0])},${e.target.value
                .split(',')[1]
                .substring(0, 2)}`;
            }
          } else {
            e.target.value = thousandDot(e.target.value);
          }
          value.value = e.target.value
            .replace(/,(?=[^,]+$)/, '*')
            .replace(/\./g, '')
            .replace(/\*/g, '.');
        }
      }
    };
    const preventWrittenLetters = e => {
      if (e.key?.length === 1 && !/^[0-9]*,?[0-9]*$/.test(e.key) && !e.metaKey) {
        e.preventDefault();
      }
    };
    const preventPastedLetters = e => {
      if (!/^[0-9]*,?[0-9]*$/.test(e.clipboardData.getData('text'))) {
        e.preventDefault();
        return false;
      }
    };
    const cleanValue = e => {
      if (e.target.value) {
        e.target.value = e.target.value.replace(/\./g, '');
      }
    };
    const clearField = () => {
      value.value = '';
      extValue.value = '';
      selection.value = false;
      modified.value = false;
      setValue('', true);
    };
    if (typeof extValue.value !== 'undefined') {
      const setExtValue = extValue.value || '';
      setValue(setExtValue, true);
    }
    return {
      ev,
      id,
      min,
      max,
      value,
      error,
      valid,
      pattern,
      decimals,
      extValue,
      setValue,
      disabled,
      required,
      selection,
      inputType,
      cleanValue,
      clearField,
      placeholder,
      releaseValue,
      preventPastedLetters,
      preventWrittenLetters
    };
  }
};
</script>
