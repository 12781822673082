<template>
  <div v-if="label || indication || !hideSeparator || hasSlot('groups')" class="flex flex-col">
    <label v-if="label" :for="id" class="block text-sm font-bold break-words">{{ label }}</label>
    <hr v-if="!hideSeparator" class="mt-1 mb-1.5 border-gray-soft" role="separator" />
    <span v-if="indication" class="block mb-3 whitespace-pre-line break-words text-xs text-blue">
      {{ indication }}
    </span>
    <slot v-if="hasSlot('groups')" name="groups" />
    <div
      v-else
      :class="`relative flex-1 ${
        /radio|check/.test(type)
          ? `${
              type === 'check' ? 'flex flex-row-reverse justify-end items-center' : ''
            } py-6 lg:pb-3`
          : 'pt-6 pb-10 lg:pb-9'
      }`"
    >
      <span
        v-if="placeholder"
        :class="`w-full break-words ${
          type === 'check'
            ? 'block font-medium ml-2'
            : 'absolute left-0 pl-1 pb-1 text-xs font-semibold transform -translate-y-full'
        }`"
      >
        {{ placeholder }}
      </span>
      <span
        v-if="suggestion && !disabled"
        class="absolute flex right-0 transform -translate-y-2.5 translate-x-2.5 rounded-full p-0.5 hover:bg-opacity-90 bg-blue"
        @mouseenter="setSuggestion(true)"
        @mouseleave="setSuggestion(false)"
      >
        <icon name="pt-question-circle" size="1.3" color="white" />
      </span>
      <transition
        enter-active-class="duration-300 ease-out"
        enter-from-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="duration-200 ease-in"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <span
          v-if="suggestionState.show && suggestion && !disabled"
          class="absolute top-0 right-0 ml-3 mr-7 p-2.5 px-3 text-sm shadow rounded break-words text-center font-semibold text-white bg-opacity-90 bg-gray"
          @mouseenter="suggestionState.wait = true"
          @mouseleave="suggestionState.wait = false"
          v-text="suggestion"
        />
      </transition>
      <component
        :is="setInputType"
        ref="fields"
        autocomplete="off"
        @click="click"
        @set-value="setValue"
        @change-value="changeValue"
      />
      <transition
        enter-active-class="transition ease-out duration-100"
        enter-from-class="transform opacity-0 scale-95"
        enter-to-class="transform opacity-100 scale-100"
        leave-active-class="transition ease-in duration-75"
        leave-from-class="transform opacity-100 scale-100"
        leave-to-class="transform opacity-0 scale-95"
      >
        <span v-if="setErrorShow" class="absolute pl-1 pt-1 text-xs italic text-red">
          {{ errors }}
        </span>
      </transition>
    </div>
  </div>
  <div
    v-else
    :class="`relative flex-1 ${
      /radio|check/.test(type)
        ? `${type === 'check' ? 'flex flex-row-reverse justify-end items-center' : ''} py-6 lg:pb-3`
        : 'pt-6 pb-10 lg:pb-9'
    }`"
  >
    <span
      v-if="placeholder"
      :class="[
        'w-full break-words',
        type === 'check'
          ? 'block font-medium ml-2'
          : `absolute left-0 pl-1 text-xs font-semibold transform -translate-y-full ${
              type === 'radio' ? 'pb-3' : 'pb-1'
            }`
      ]"
      v-text="placeholder"
    />
    <span
      v-if="suggestion && !disabled"
      class="absolute flex right-0 transform -translate-y-2.5 translate-x-2.5 rounded-full p-0.5 hover:bg-opacity-90 bg-blue"
      @mouseenter="setSuggestion(true)"
      @mouseleave="setSuggestion(false)"
    >
      <icon name="pt-question-circle" size="1.3" color="white" />
    </span>
    <transition
      enter-active-class="duration-300 ease-out"
      enter-from-class="transform opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="duration-200 ease-in"
      leave-from-class="opacity-100"
      leave-to-class="transform opacity-0"
    >
      <span
        v-if="suggestionState.show && suggestion && !disabled"
        class="absolute top-0 right-0 ml-3 mr-7 p-2.5 px-3 text-sm shadow rounded break-words text-center font-semibold text-white bg-opacity-90 bg-gray"
        @mouseenter="suggestionState.wait = true"
        @mouseleave="suggestionState.wait = false"
        v-text="suggestion"
      />
    </transition>
    <component
      :is="setInputType"
      ref="fields"
      autocomplete="off"
      @click="click"
      @set-value="setValue"
      @change-value="changeValue"
    />
    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <span v-if="setErrorShow" class="absolute pl-1 pt-1 text-xs italic text-red">
        {{ errors }}
      </span>
    </transition>
  </div>
</template>
<script>
import slot from '@/mixins/slot';
import TypeInput from './TypeInput';
import TypeSelect from './TypeSelect';
import TypeRadio from './TypeRadio';
import TypeCheck from './TypeCheck';
import Icon from '@/components/Icon';
import { ref, toRefs, computed, watch, watchPostEffect, reactive } from 'vue';
export default {
  name: 'PtForm',
  components: { TypeInput, TypeSelect, TypeRadio, TypeCheck, Icon },
  provide() {
    return {
      inputType: computed(() => this.inputType),
      placeholder: computed(() => this.placeholder),
      id: computed(() => this.id),
      selection: computed({
        get: () => this.selection,
        set: val => {
          this.selection = val;
        }
      }),
      extValue: computed({
        get: () => this.getExtValue,
        set: val => {
          this.getExtValue = val;
        }
      }),
      inputs: computed(() => this.inputs),
      disabled: computed(() => this.disabled),
      required: computed(() => this.required),
      error: computed(() => this.error),
      propsName: computed(() => ({
        idPropName: this.idPropName,
        titlePropName: this.titlePropName
      })),
      pattern: computed(() => this.pattern),
      numberSettings: computed(() => ({
        maxValue: this.maxValue,
        minValue: this.minValue,
        decimals: this.decimals
      })),
      expandParameters: computed(() => this.expandParameters)
    };
  },
  props: {
    type: {
      type: String,
      default: 'input',
      validator: val => /input|select|radio|check/.test(val)
    },
    id: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: ''
    },
    indication: {
      type: String,
      default: ''
    },
    suggestion: {
      type: String,
      default: ''
    },
    error: {
      type: [String, Array],
      default: '',
      validator: val => (Array.isArray(val) ? val.length <= 2 : String)
    },
    inputs: {
      type: Array,
      default: () => []
    },
    // eslint-disable-next-line vue/require-prop-types
    extValue: {
      default: undefined
    },
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    hideSeparator: {
      type: Boolean,
      default: false
    },
    inputType: {
      type: String,
      default: 'text',
      validator: val => /text|number|date|email|password/.test(val)
    },
    placeholder: {
      type: [String, Number],
      default: ''
    },
    idPropName: {
      type: String,
      default: 'id'
    },
    titlePropName: {
      type: String,
      default: 'title'
    },
    pattern: {
      type: [String, Array, Boolean],
      default: undefined
    },
    maxValue: {
      type: [String, Number],
      default: ''
    },
    minValue: {
      type: [String, Number],
      default: ''
    },
    decimals: {
      type: Boolean,
      default: false
    },
    expandParameters: {
      type: Boolean,
      default: false
    }
  },
  emits: ['formData', 'changeValue', 'evClick'],
  setup(props, { emit }) {
    const { hasSlot } = slot();
    const fields = ref(null);
    const selection = ref(false);
    const valid = ref(false);
    const value = ref(undefined);
    const suggestionState = reactive({
      show: false,
      timer: 0,
      wait: false
    });
    const { error, extValue, type, disabled, required } = toRefs(props);
    const { show, timer, wait } = toRefs(suggestionState);
    const errors = ref(Array.isArray(error.value) ? error.value[0] : error.value);
    const getExtValue = ref(extValue.value);
    const setInputType = computed(() => `Type${type.value[0].toUpperCase() + type.value.slice(1)}`);
    const setValue = val => {
      if (!disabled.value) {
        valid.value = val.validation;
        value.value = val.value;
        if (val.errors) errors.value = val.errors;
      }
    };
    const changeValue = item => {
      if (!disabled.value) {
        emit('changeValue', item);
      }
    };
    const click = () => {
      if (!disabled.value) emit('evClick');
    };
    const setErrorShow = computed(
      () =>
        !disabled.value &&
        !valid.value &&
        (required.value || value.value) &&
        selection.value &&
        error.value
    );
    const setSuggestion = state => {
      clearTimeout(timer.value);
      if (state) {
        show.value = true;
      } else {
        if (!wait.value) {
          timer.value = setTimeout(() => {
            show.value = false;
          }, 500);
        }
      }
    };
    watch(
      () => wait.value,
      val => {
        setSuggestion(val);
      }
    );
    watchPostEffect(() => emit('formData', fields));
    return {
      click,
      valid,
      value,
      errors,
      fields,
      hasSlot,
      setValue,
      selection,
      changeValue,
      getExtValue,
      setErrorShow,
      setInputType,
      setSuggestion,
      suggestionState
    };
  }
};
</script>
