<template>
  <div v-if="direction === 'vertical'" class="w-1 h-full py-4 overflow-hidden bg-white">
    <div
      class="h-8 overflow-hidden transition-all bg-orange-arable"
      :style="`margin-top: ${5 * currentStepHTML}rem`"
    />
  </div>
  <nav class="flex" aria-label="Breadcrumb">
    <ol
      ref="list"
      role="list"
      :class="`flex ${
        direction === 'horizontal'
          ? 'space-x-3 flex-row items-center'
          : 'flex-col ml-6 py-5 space-y-14'
      }`"
    >
      <li v-for="(item, index) in items" :id="item.value.toLowerCase()" :key="index">
        <div class="flex items-center">
          <icon
            v-if="index !== 0 && direction === 'horizontal'"
            class="flex-shrink-0"
            name="pt-angle-right-b"
            color="gray"
          />
          <component
            :is="toQuery ? 'div' : 'router-link'"
            :to="toQuery ? null : setterHref[index]"
            :class="`text-sm font-medium ${
              setterCurrent[index]
                ? `cursor-default text-orange-arable ${clickable ? '' : 'pointer-events-none'}`
                : (index < currentStep && !disabled) || clickable
                ? 'cursor-pointer text-gray hover:text-gray-dark'
                : 'cursor-default text-gray-light'
            } ${direction === 'horizontal' && index !== 0 ? 'ml-3' : ''} ${classItem}`"
            @click="clickBreadcrumb(item)"
          >
            {{ setterName[index] }}
          </component>
        </div>
      </li>
    </ol>
  </nav>
</template>
<script>
import { ref, computed, watchPostEffect } from 'vue';
import dynamicElements from '@/mixins/dynamicElements';
import Icon from '@/components/Icon';
export default {
  name: 'PtBreadcrumbs',
  components: { Icon },
  props: {
    items: {
      type: Array,
      required: true
    },
    name: {
      type: [String, Array],
      default: 'name'
    },
    current: {
      type: [String, Array],
      default: 'current'
    },
    href: {
      type: [String, Array],
      default: 'href'
    },
    value: {
      type: [String, Array],
      default: 'value'
    },
    direction: {
      type: String,
      default: 'horizontal',
      validator: val => /horizontal|vertical/.test(val)
    },
    disabled: {
      type: Boolean,
      default: false
    },
    clickable: {
      type: Boolean,
      default: false
    },
    toQuery: {
      type: Boolean,
      default: false
    },
    classItem: {
      type: String,
      default: ''
    }
  },
  emits: ['clickBreadcrumb'],
  setup(props, { emit }) {
    const { setElement } = dynamicElements();
    const list = ref(null);
    const currentStepHTML = ref(0);
    const setterName = computed(() => props.items.map(item => setElement(item, props.name)));
    const setterCurrent = computed(() => props.items.map(item => setElement(item, props.current)));
    const setterHref = computed(() => props.items.map(item => setElement(item, props.href)));
    const currentStep = computed(() =>
      props.items.findIndex(item => setElement(item, props.current))
    );
    watchPostEffect(() => {
      const element = props.items.find(item => setElement(item, props.current));
      currentStepHTML.value =
        element != null
          ? [...list.value.querySelectorAll('li')]
              .map(el => el.id)
              .findIndex(tag => tag === element.value.toLowerCase())
          : null;
    });
    const clickBreadcrumb = el => {
      emit('clickBreadcrumb', el);
    };
    return {
      list,
      setterName,
      setterHref,
      currentStep,
      setterCurrent,
      currentStepHTML,
      clickBreadcrumb
    };
  }
};
</script>
