<template>
  <Menu
    as="div"
    class="relative inline-block text-left w-full"
    :class="isBlocked && 'pointer-events-none'"
  >
    <MenuButton
      class="w-full inline-flex justify-center py-4 whitespace-nowrap text-sm font-medium transition"
      :class="{ 'opacity-50 pointer-events-none': disabled }"
      @[disabledClick]="newOption"
    >
      <div :class="`w-full flex justify-between items-center space-x-2 px-4 ${selectStyle}`">
        <span class="font-sans text-sm">{{ selectedOption }}</span>
        <div v-if="loading" class="relative w-3 h-3">
          <Loading height-width="3" color="white" :show-wrapper="false" />
        </div>
        <icon
          v-if="!isBlocked && !loading"
          :name="options.length > 0 && !disabled ? 'pt-angle-down' : 'pt-plus-circle'"
          color="white"
          :size="options.length > 0 && !disabled ? '1.2' : '1'"
          aria-hidden="true"
        />
      </div>
    </MenuButton>
    <template v-if="options.length > 0">
      <transition
        enter-active-class="transition ease-out duration-100"
        enter-from-class="transform opacity-0 scale-95"
        enter-to-class="transform opacity-100 scale-100"
        leave-active-class="transition ease-in duration-75"
        leave-from-class="transform opacity-100 scale-100"
        leave-to-class="transform opacity-0 scale-95"
      >
        <MenuItems
          class="overflow-y-auto top-12 text-sm origin-top-right absolute left-0 mt-1 ml-1 w-full rounded-md shadow-lg bg-gray-very-light ring-1 text-gray-dark ring-black ring-opacity-5 focus:outline-none"
          style="width: calc(100% - 0.5rem); max-height: calc(100vh - 10rem)"
        >
          <MenuItem v-for="option in options" :key="option" v-slot="{ active }">
            <div
              class="relative flex justify-center items-center m-1 overflow-hidden rounded text-sm transition-shadow hover:border hover:shadow-md text-gray cursor-pointer"
            >
              <div class="w-full p-2 bg-white" @click="selectOption(option)">
                <div class="mr-6 overflow-hidden whitespace-nowrap overflow-ellipsis">
                  <span>{{ option.nombre }}</span>
                  <span v-if="propDetailName && option[propDetailName]" class="ml-0.5">
                    ({{ option[propDetailName] }})
                  </span>
                </div>
              </div>
              <div class="absolute h-full right-0" @click="editOption(option)">
                <icon
                  class="h-full flex items-center p-2 text-center transition-colors text-white hover:text-white bg-blue lg:bg-white hover:bg-blue-light"
                  :class="active ? 'lg:text-gray' : 'lg:text-gray-light'"
                  name="pt-edit-alt"
                />
              </div>
            </div>
          </MenuItem>
          <div
            v-if="addedText"
            class="sticky flex justify-center items-center bottom-0 border-t border-gray-very-light cursor-pointer text-blue bg-gray-soft"
            @click="newOption"
          >
            <div class="flex items-center justify-between flex-1 p-2 hover:bg-opacity-80 bg-white">
              <span>
                {{ addedText }}
              </span>
              <icon name="pt-plus-circle" class="inline-block ml-2" />
            </div>
          </div>
        </MenuItems>
      </transition>
    </template>
  </Menu>
</template>

<script>
import { computed } from 'vue';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import Icon from '@/components/Icon';
import Loading from '../Loading';

export default {
  name: 'PtMenuItem',
  components: {
    // eslint-disable-next-line vue/no-reserved-component-names
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    Loading,
    Icon
  },
  props: {
    options: {
      type: Array,
      default: () => []
    },
    property: {
      type: String,
      default: ''
    },
    addedText: {
      type: String,
      default: ''
    },
    defaultText: {
      type: String,
      default: ''
    },
    selected: {
      type: String,
      default: ''
    },
    isBlocked: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    selectStyle: {
      type: String,
      default: ''
    },
    propDetailName: {
      type: String,
      default: undefined
    }
  },
  emits: ['newOption', 'changeOption', 'editOption'],
  setup(props, { emit }) {
    const selectedOption = computed(() =>
      props.selected === '' ? props.defaultText : props.selected
    );
    const selectOption = option => {
      if (isDifferentOption(option.nombre)) {
        const selectedValue = {
          property: props.property,
          option: option
        };
        emit('changeOption', selectedValue);
      }
    };
    const isDifferentOption = selectedOption => selectedOption !== props.selected;
    const disabledClick = computed(() =>
      props.options.length === 0 && !props.loading ? (props.disabled ? null : 'click') : null
    );
    const editOption = option => {
      emit('editOption', option);
    };
    const newOption = () => {
      emit('newOption');
    };

    return {
      selectOption,
      editOption,
      newOption,
      selectedOption,
      disabledClick
    };
  }
};
</script>
