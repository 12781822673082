<template>
  <div
    v-if="pwa"
    class="w-full lg:w-screen lg:max-w-xl flex items-center space-x-5 overflow-hidden rounded bg-gray-very-light"
  >
    <div
      class="w-full h-full flex items-center justify-evenly p-2.5 hover:bg-opacity-70 bg-blue-very-light"
      @click="openPwa"
    >
      <img class="w-auto h-full p-1.5 shadow-lg rounded bg-white" :src="logo" />
      <span class="whitespace-pre font-bold text-sm lg:text-base text-blue">{{ text }}</span>
      <icon class="mr-2" color="blue" name="pt-download-alt" size="1.4" />
    </div>
    <icon
      class="absolute top-0 right-0 p-1 lg:p-2.5"
      name="pt-close-circle"
      size="1.3"
      @click="close"
    />
  </div>
  <div v-else :class="setterPosition.grandParent">
    <div :class="['pt-alert-control', setterPosition.parent]">
      <div
        v-click-outside="close"
        :class="['pt-alert-control_banner', setterPosition.child, setterColor]"
      >
        <div class="pt-alert-control">
          <icon
            v-if="icon"
            class="mr-2 text-xl"
            :name="icon.name"
            :type="icon.type"
            :color="icon.color"
          />
          <!-- eslint-disable-next-line vue/no-v-html -->
          <span :class="['whitespace-pre-line', textStyle]" v-html="text" />
        </div>
        <icon
          v-if="showCloseButton"
          class="text-xs cursor-pointer"
          name="pt-multiply"
          color="gray"
          @click="close"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Icon from '@/components/Icon';
import { computed } from 'vue';
export default {
  name: 'PtAlert',
  components: { Icon },
  directives: {
    'click-outside': {
      beforeMount(el, binding) {
        // eslint-disable-next-line no-param-reassign
        el.clickOutsideEvent = evt => {
          evt.stopPropagation();
          if (!(el === evt.target || el.contains(evt.target))) {
            binding.value(evt, el);
          }
        };
        window.requestAnimationFrame(() => {
          document.addEventListener('click', el.clickOutsideEvent);
        });
      },
      unmounted(el) {
        document.removeEventListener('click', el.clickOutsideEvent);
      }
    }
  },
  props: {
    icon: {
      type: Object,
      default: () => {}
    },
    text: {
      type: String,
      required: true
    },
    position: {
      type: String,
      default: 'free',
      validator: val => /free|top|right|bottom|left/.test(val)
    },
    textStyle: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'success'
    },
    pwa: {
      type: Boolean,
      default: false
    },
    logo: {
      type: String,
      default: ''
    },
    showCloseButton: {
      type: Boolean,
      default: true
    }
  },
  emits: ['close', 'openPwa'],
  setup(props, { emit }) {
    const types = {
      success: 'bg-green-alert',
      info: 'bg-blue-light',
      warning: 'bg-orange-pastel',
      danger: 'bg-red-pastel'
    };
    const positions = {
      grandParent: {
        free: 'relative',
        top: 'absolute right-0 left-0 top-28 lg:top-20',
        right: 'absolute right-1 top-28 lg:top-20',
        bottom: 'relative',
        left: 'absolute top-28 lg:top-20'
      },
      parent: {
        free: '',
        top: 'positioned justify-center',
        right: 'positioned justify-end',
        bottom: 'positioned justify-center',
        left: 'positioned justify-start'
      },
      child: {
        free: '',
        top: 'positioned max-w-4xl',
        right: 'positioned max-w-lg right-0 lg:right-auto',
        bottom: 'positioned max-w-5xl bottom-6 justify-center',
        left: 'positioned max-w-lg left-1 lg:left-auto'
      }
    };
    const close = () => emit('close');
    const openPwa = () => emit('openPwa');
    const setterColor = computed(() => types[props.type]);
    const setterPosition = computed(() => ({
      grandParent: positions.grandParent[props.position],
      parent: positions.parent[props.position],
      child: positions.child[props.position]
    }));
    return {
      close,
      openPwa,
      setterColor,
      setterPosition
    };
  }
};
</script>
