<template>
  <div>
    <span v-if="title" class="block mb-4" v-text="title" />
    <ul class="max-h-96 overflow-y-auto divide-y divide-gray-soft">
      <template v-for="(item, index) in list" :key="index">
        <li
          class="flex flex-col xs:flex-row space-y-2.5 xs:space-y-0 items-center justify-between p-5 bg-white"
        >
          <div
            class="flex flex-col xs:flex-row space-y-1.5 xs:space-y-0 items-center space-x-1 truncate"
          >
            <span class="font-semibold" v-text="item[titlePropName]" />
            <template v-if="item[descriptionPropName]">
              <span class="hidden xs:block font-semibold text-gray" v-text="'·'" />
              <span class="font-light text-xs text-gray" v-text="item[descriptionPropName]" />
            </template>
          </div>
          <div v-if="hasSlot('actions')" class="grid grid-flow-col gap-8">
            <slot name="actions" :get-item="item" />
          </div>
        </li>
      </template>
    </ul>
  </div>
</template>
<script>
import slot from '@/mixins/slot';
export default {
  name: 'PtList',
  props: {
    list: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      default: ''
    },
    titlePropName: {
      type: String,
      default: 'name'
    },
    descriptionPropName: {
      type: String,
      default: 'description'
    }
  },
  setup() {
    const { hasSlot } = slot();
    return {
      hasSlot
    };
  }
};
</script>
