<template>
  <div>
    <component :is="heading ? `h${heading}` : 'span'" class="block">
      {{ text }}
    </component>
    <hr
      v-if="separator"
      role="separator"
      aria-orientation="horizontal"
      class="mt-1 mb-1.5 border-gray-soft"
    />
  </div>
</template>

<script>
export default {
  name: 'PtTitle',
  props: {
    heading: {
      type: String,
      default: '',
      validator: val => /1|2|3|4|5|6|/.test(val)
    },
    text: {
      type: String,
      default: ''
    },
    separator: {
      type: Boolean,
      default: false
    }
  }
};
</script>
