export default function () {
  const formatHorizontalTable = (items, headers) => {
    const data = [];
    headers.forEach((e, i) => {
      const prop = Object.keys(items[0])[i];
      let row = {};
      items.forEach((item, index) => {
        row['col' + index] = item[prop];
      });
      data.push({ header: { text: e }, ...row });
    });
    return data;
  };
  return {
    formatHorizontalTable
  };
}
