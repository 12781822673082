<template>
  <div
    class="flex flex-col lg:flex-row items-center justify-between h-14 bg-white lg:px-3 border-b border-gray-very-light"
  >
    <div class="text-blue font-bold text-sm">{{ title }}</div>
    <div class="flex w-full lg:max-w-max">
      <div v-for="(button, index) in options" :key="button.label" class="hidden lg:flex">
        <button
          class="flex justify-center items-center py-2 px-3 hover:bg-gray-very-light text-sm font-semibold text-gray"
          @click="emitButtonEvent(button.emit)"
        >
          <icon class="mr-2 min-w-max" :name="button.icon.name" :color="button.icon.color" />
          <span>{{ button.label }}</span>
        </button>
        <div v-if="index < options.length - 1" class="border-l border-gray-very-light"></div>
      </div>
      <div class="flex items-center w-full lg:hidden">
        <button
          class="flex flex-1 justify-center items-center w-full py-2 px-3 hover:bg-gray-very-light text-sm font-semibold text-gray"
          @click="emitButtonEvent(options[selectedOption].emit)"
        >
          <icon
            class="mr-2 min-w-max"
            :name="options[selectedOption].icon.name"
            :color="options[selectedOption].icon.color"
          />
          <span>{{ options[selectedOption].label }}</span>
        </button>
        <div class="flex space-x-2">
          <icon
            class="cursor-pointer"
            color="gray"
            name="pt-angle-left"
            @click="showPreviousButton"
          />
          <icon class="cursor-pointer" color="gray" name="pt-angle-right" @click="showNextButton" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/Icon';
import { ref, computed } from 'vue';
export default {
  name: 'PtToolbar',
  components: {
    Icon
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    buttons: {
      type: Array,
      default: () => []
    }
  },
  emits: ['clickedOption'],
  setup(props, { emit }) {
    const selectedOption = ref(0);
    const emitButtonEvent = eventName => emit('clickedOption', eventName);
    const options = computed(() => props.buttons.filter(button => button.visible !== false));
    const showNextButton = () => {
      selectedOption.value =
        selectedOption.value === options.value.length - 1 ? 0 : selectedOption.value + 1;
    };
    const showPreviousButton = () => {
      selectedOption.value =
        selectedOption.value === 0 ? options.value.length - 1 : selectedOption.value - 1;
    };
    return {
      options,
      showNextButton,
      selectedOption,
      emitButtonEvent,
      showPreviousButton
    };
  }
};
</script>
