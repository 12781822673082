<template>
  <div class="bg-white border-b border-gray-very-light">
    <div
      class="text-gray p-4 rounded cursor-pointer flex items-center justify-between"
      @click="isOpen = !isOpen"
    >
      <slot v-if="hasSlot('title')" name="title" />
      <div v-if="showIcon" class="flex items-center rotate-180">
        <PtIcon
          name="pt-angle-down"
          color="blue"
          class="text-2xl"
          :style="isOpen && 'transform: rotate(180deg)'"
        />
      </div>
    </div>
    <div
      v-if="hasSlot('content')"
      class="p-4 rounded transition-all"
      :class="isOpen ? 'block' : 'hidden'"
    >
      <slot name="content" />
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import PtIcon from '../Icon';
import slot from '@/mixins/slot';
export default {
  name: 'PtAccordion',
  components: {
    PtIcon
  },
  props: {
    showIcon: {
      type: Boolean,
      default: false
    },
    showOpen: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const { hasSlot } = slot();
    const isOpen = ref(props.showOpen);
    return {
      isOpen,
      hasSlot
    };
  }
};
</script>
