import { createStore } from 'vuex';

const store = createStore({
  state: {
    filter: ''
  },
  getters: {
    getByField: state => field => state[field]
  },
  mutations: {
    setFilter: (state, filter) => {
      state.filter = filter;
    }
  }
});

export default store;
