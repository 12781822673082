<template>
  <div
    class="flex flex-col space-y-5 overflow-x-hidden overflow-y-visible rounded-lg shadow-md transform transition-all"
    :class="(!fullHeight && 'xl:h-fit', bgColor)"
  >
    <slot name="content" />
    <div v-if="hasSlot('footer')" class="w-full flex justify-center">
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
import slot from '@/mixins/slot';
export default {
  name: 'PtCard',
  props: {
    fullHeight: {
      type: Boolean,
      default: false
    },
    bgColor: {
      type: String,
      default: 'bg-white'
    }
  },
  setup() {
    const { hasSlot } = slot();
    return {
      hasSlot
    };
  }
};
</script>
