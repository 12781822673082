// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bar[data-v-430520b5] {
  transition: width 1s;
}
`, "",{"version":3,"sources":["webpack://./src/components/Chart/Bar/index.vue"],"names":[],"mappings":"AA2CA;EAEE,oBAAoB;AACtB","sourcesContent":["<template>\n  <div class=\"w-full h-5 rounded-lg overflow-hidden\" style=\"background-color: #f4f4f4\">\n    <div\n      class=\"bar h-5 rounded-l-lg\"\n      :class=\"`${globalProps.data === 100 && 'rounded-lg'} bg-${colorByType}`\"\n      :style=\"`width: ${widthBar}%; background-color: ${backgroundColor}`\"\n    ></div>\n  </div>\n</template>\n\n<script>\nimport { ref, inject, computed, onMounted } from 'vue';\nexport default {\n  name: 'PtChartBar',\n  setup() {\n    const globalProps = inject('global');\n    const types = {\n      success: 'green-alert',\n      info: 'blue-light',\n      warning: 'yellow-light',\n      danger: 'red-light'\n    };\n    const widthBar = ref(0);\n    const colorByType = types[globalProps.value.type];\n    const backgroundColor = computed(() =>\n      Array.isArray(globalProps.value.colors)\n        ? globalProps.value.colors[0]\n        : globalProps.value.colors\n    );\n    onMounted(() => {\n      Vue.nextTick(() => (widthBar.value = globalProps.value.data));\n    });\n    return {\n      widthBar,\n      colorByType,\n      globalProps,\n      backgroundColor\n    };\n  }\n};\n</script>\n\n<style scoped>\n.bar {\n  -webkit-transition: width 1s;\n  transition: width 1s;\n}\n</style>\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
