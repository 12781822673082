<template>
  <fieldset>
    <ul class="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
      <li v-for="(radio, index) in radioInputs" :key="setterId[index]" class="flex items-center">
        <input
          :id="setterId[index]"
          :key="key"
          :name="id"
          :checked="
            extValueType.str && !extValueType.num
              ? setterTitle[index] === setChecked
              : index === +setChecked
          "
          type="radio"
          class="pt-form-radio"
          @click="setCurrent(index)"
        />
        <label :for="setterId[index]" class="ml-3 block text-sm font-medium cursor-pointer">
          {{ setterTitle[index] }}
        </label>
      </li>
    </ul>
  </fieldset>
</template>
<script>
import { ref, inject, computed } from 'vue';
export default {
  name: 'PtRadio',
  setup() {
    const propsName = inject('propsName');
    const id = inject('id');
    const radioInputs = inject('inputs');
    const extValue = inject('extValue');
    const value = ref(0);
    const textValue = ref('');
    const key = ref(0);
    const extValueType = computed(() => ({
      str:
        extValue.value !== undefined &&
        (typeof extValue.value === 'string' || extValue.value instanceof String),
      bool: extValue.value !== undefined && !!extValue.value === extValue.value,
      num:
        extValue.value &&
        !isNaN(
          +extValue.value
            .toString()
            .replace(/,/g, '')
            .replace(/[.](?=.*[.])/g, '')
        )
    }));
    if (extValue.value !== undefined) {
      value.value = extValue.value;
      if (extValueType.value.bool) {
        textValue.value = radioInputs.value[+extValue.value][propsName.value.titlePropName];
      } else if (extValueType.value.str) {
        textValue.value = extValue.value;
      }
    } else if (radioInputs.value.length === 2) {
      value.value = true;
    }
    const setCurrent = index => {
      value.value = radioInputs.value.length === 2 ? !index : index;
      textValue.value = radioInputs.value[index][propsName.value.titlePropName];
    };
    const setChecked = computed(() => {
      if (extValueType.value.bool) {
        return +!extValue.value;
      } else if (extValueType.value.str && !extValueType.value.num) {
        return extValue.value;
      } else if (extValueType.value.num) {
        return +extValue.value;
      } else {
        return 0;
      }
    });
    const setterTitle = computed(() =>
      radioInputs.value.map(input => input[propsName.value.titlePropName])
    );
    const setterId = computed(() =>
      radioInputs.value.map(input => input[propsName.value.idPropName])
    );
    const clearField = () => {
      setCurrent(0);
      key.value++;
    };
    return {
      id,
      key,
      value,
      extValue,
      setterId,
      textValue,
      setCurrent,
      clearField,
      setChecked,
      radioInputs,
      setterTitle,
      extValueType
    };
  }
};
</script>
