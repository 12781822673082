// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pt-popup {
  min-height: 400px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Popup/index.vue"],"names":[],"mappings":"AAkDA;EACE,iBAAiB;AACnB","sourcesContent":["<template>\n  <TransitionRoot as=\"template\" :show=\"open\">\n    <TransitionChild\n      as=\"template\"\n      enter=\"ease-out duration-300\"\n      enter-from=\"opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95\"\n      enter-to=\"opacity-100 translate-y-0 sm:scale-100\"\n      leave=\"ease-in duration-200\"\n      leave-from=\"opacity-100 translate-y-0 sm:scale-100\"\n      leave-to=\"opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95\"\n    >\n      <div\n        :class=\"[\n          'flex flex-col items-center justify-center space-y-5 px-4 pt-5 pb-4 sm:p-9 text-center overflow-hidden rounded-lg shadow-md transform transition-all mx-auto my-0 bg-white',\n          full ? 'w-full' : 'w-auto sm:w-full sm:max-w-xl'\n        ]\"\n      >\n        <slot v-if=\"hasSlot('content')\" name=\"content\" />\n        <div v-if=\"hasSlot('closer')\" @click=\"open = false\">\n          <slot name=\"closer\" />\n        </div>\n      </div>\n    </TransitionChild>\n  </TransitionRoot>\n</template>\n\n<script>\nimport slot from '@/mixins/slot';\nimport { ref } from 'vue';\nimport { TransitionRoot, TransitionChild } from '@headlessui/vue';\nexport default {\n  name: 'PtPopup',\n  components: { TransitionRoot, TransitionChild },\n  props: {\n    full: {\n      type: Boolean,\n      default: false\n    }\n  },\n  setup() {\n    const { hasSlot } = slot();\n    const open = ref(true);\n    return {\n      open,\n      hasSlot\n    };\n  }\n};\n</script>\n<style lang=\"css\">\n.pt-popup {\n  min-height: 400px;\n}\n</style>\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
