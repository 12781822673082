<template>
  <div>
    <span class="text-sm text-gray font-bold">{{ title }}</span>
    <ul>
      <li
        v-for="(item, key, index) in data"
        :key="item.label"
        class="flex justify-between items-center text-xs"
        :class="index !== Object.keys(data).length - 1 && 'border-b border-gray-light'"
      >
        <div class="flex items-center py-3">
          <icon :name="`pt-${item.icon}`" class="text-base mr-2" color="gray-dark" />
          <span class="text-gray-dark">{{ item.label }}</span>
        </div>
        <span class="font-bold">
          {{ item.value }}
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
import Icon from '@/components/Icon';

export default {
  name: 'PtWeatherDayDetail',
  components: {
    Icon
  },

  props: {
    data: {
      type: Object,
      default: () => {}
    },
    title: {
      type: String,
      default: ''
    }
  },
  setup() {
    return {};
  }
};
</script>

<style></style>
