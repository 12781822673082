import { ref } from 'vue';

export default function () {
  const options = ref({});
  const series = ref([]);
  const conf = ref({});

  const chartOptions = {
    chart: {
      width: '100%',
      type: '',
      fontFamily: '"Source Sans Pro", sans-serif'
    },
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        dataLabels: {
          value: {
            show: false
          }
        }
      }
    },
    xaxis: {
      categories: []
    },
    labels: []
  };
  const initConf = (data, type, labels, xaxis, colors) => {
    conf.value = { data, type, labels, xaxis, colors };
  };
  const getChartConfig = () => {
    options.value = chartOptions;
    options.value.chart.type = conf.value.type;
    options.value.xaxis.categories = conf.value.xaxis;
    if (conf.value.colors?.length > 0) options.value.colors = conf.value.colors;
    setLabels(options);

    return { options, series };
  };
  const setLabels = () => {
    switch (conf.value.type) {
      case 'line':
      case 'bar':
        series.value = getSerieObj();
        return;
      case 'pie':
      case 'donut':
      case 'radialBar':
        options.value.labels = conf.value.labels;
        series.value = conf.value.data;
        return;
    }
  };
  const getSerieObj = () => {
    const series = [];
    conf.value.data.forEach((data, index) => {
      series.push({
        name: conf.value.labels[index],
        data: data
      });
    });
    return series;
  };
  return {
    getChartConfig,
    initConf
  };
}
