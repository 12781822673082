export default function () {
  // no utilizar el smooth-scroll nativo porque no es compatible con Safari
  const scrollToSmoothly = (pos, time = 300) => {
    const currentPos = window.pageYOffset;
    const countHead = window.matchMedia('(min-width: 768px)').matches ? 2 : 1;
    const endPos = Number(
      typeof pos === 'string'
        ? document.querySelector(`#${pos}`).offsetTop -
            document.querySelector('header').offsetHeight * countHead
        : pos
    );
    let start = null;
    Number(time);
    window.requestAnimationFrame(function step(currentTime) {
      start = !start ? currentTime : start;
      const progress = currentTime - start;
      if (currentPos < endPos) {
        window.scrollTo(0, ((endPos - currentPos) * progress) / time + currentPos);
      } else {
        window.scrollTo(0, currentPos - ((currentPos - endPos) * progress) / time);
      }
      if (progress < time) {
        window.requestAnimationFrame(step);
      } else {
        window.scrollTo(0, endPos);
      }
    });
  };
  return {
    scrollToSmoothly
  };
}
