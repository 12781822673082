<template>
  <div ref="container" class="relative">
    <div @click="toggle">
      <slot name="opener" />
    </div>
    <Transition>
      <div
        v-if="open"
        class="dropdown-body bg-white shadow-lg p-2 rounded-sm mt-5 top-full bottom-auto right-0 overflow-y-auto transition-all"
      >
        <slot></slot>
      </div>
    </Transition>
  </div>
</template>
<script>
import { ref, onMounted, onUnmounted } from 'vue';
export default {
  name: 'PtDropdown',
  props: {},
  emits: ['opened'],
  setup(_, { emit }) {
    const container = ref(null);
    const open = ref(false);

    const toggle = () => {
      open.value = !open.value;
      emit('opened', open.value);
    };
    const openDropdown = () => {
      setTimeout(() => {
        open.value = true;
      }, 0);
    };
    const close = e => {
      if (!container.value.contains(e.target) && open.value) open.value = false;
    };

    onMounted(() => {
      document.addEventListener('click', close);
    });
    onUnmounted(() => {
      document.removeEventListener('click', close);
    });

    return {
      open,
      toggle,
      openDropdown,
      container,
      close
    };
  }
};
</script>
<style lang="scss" scope>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
.dropdown-body {
  position: absolute;
}
@media (max-width: 1023px) {
  .dropdown-body {
    position: fixed;
    width: 100vw;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 241px;
    z-index: 999;
  }
}
</style>
