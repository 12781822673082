<template>
  <div class="w-full rounded-lg">
    <div class="w-full h-full flex justify-between">
      <div class="flex flex-col justify-between text-left">
        <div class="flex flex-col mb-4">
          <span class="text-2xl">{{ farm.name }}</span>
          <span class="uppercase text-md text-gray-light">
            {{ farm.location }}
          </span>
        </div>
        <span v-if="firstDay.dayParts?.length > 0" class="text-5xl">
          {{ firstDay.dayParts[0].temperature }}º
        </span>
      </div>
      <div class="flex flex-col space-y-1 text-right self-end">
        <span v-if="firstDay.iconCode" class="mb-1">
          <icon
            :name="`pt-weather-${firstDay.iconCode}`"
            type="svg"
            class="transform origin-right scale-150"
          />
        </span>
        <span class="font-bold text-sm">{{ today }}</span>
        <span class="text-xs font-medium text-gray">{{ firstDay.phrase }}</span>
        <span class="text-xs font-semibold text-gray">{{ temperature }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import { computed } from 'vue';
import Icon from '@/components/Icon';
export default {
  name: 'PtWeatherDay',
  components: {
    Icon
  },
  props: {
    firstDay: {
      type: Object,
      default: () => {}
    },
    farm: {
      type: Object,
      default: () => {}
    },
    dynamicText: {
      type: Object,
      default: () => {}
    }
  },
  setup(props) {
    const temperature = computed(() => {
      if (props.firstDay.temperatureMax && props.firstDay.temperatureMin) {
        return `${props.dynamicText.maxText} ${props.firstDay.temperatureMax}º ${props.dynamicText.minText} ${props.firstDay.temperatureMin}º`;
      } else return '';
    });
    let today = new Date();
    const formattedDate = `${today.getDate()} ${today.toLocaleString('default', {
      month: 'short'
    })}.`;
    today = formattedDate.toString();
    return {
      today,
      temperature
    };
  }
};
</script>
