<template>
  <div :class="{ 'rounded shadow-lg p-2.5': getView.reduced }">
    <span v-if="title" class="block mb-2.5 font-bold text-gray" v-text="title" />
    <div v-if="getView.reduced" class="flex flex-wrap mb-2.5">
      <div
        v-for="(legend, indexLegend) in calendarLegends"
        :key="indexLegend"
        class="flex items-center space-x-1.5 m-2.5"
      >
        <div :class="legend.point" />
        <span class="block text-xs font-bold text-gray" v-text="legend.text" />
      </div>
    </div>
    <div class="relative sm:h-10 flex flex-wrap justify-between items-center mb-5">
      <div :class="['flex justify-center items-center', { 'w-full': getView.reduced }]">
        <div
          :class="[
            'flex items-center rounded-l rounded-r',
            getView.full ? 'justify-center bg-gray-very-light' : 'w-full justify-between'
          ]"
        >
          <Icon
            :class="['border-r-2 border-white', { 'cursor-pointer': date.month.current.min }]"
            name="angle-left"
            size="1.5"
            :color="!date.month.current.min ? 'gray-soft' : 'gray'"
            @click="changeMonth('-')"
          />
          <span
            :class="[
              'text-center font-bold font-ubuntu cursor-pointer text-gray-dark',
              getView.full ? 'w-16 p-0.5 hover:bg-gray-soft' : 'text-lg'
            ]"
            @click="goToToday"
            v-text="getView.full ? 'Hoy' : `${date.month.text} ${date.year.text}`"
          />
          <Icon
            :class="['border-l-2 border-white', { 'cursor-pointer': date.month.current.max }]"
            name="angle-right"
            size="1.5"
            :color="!date.month.current.max ? 'gray-soft' : 'gray'"
            @click="changeMonth('+')"
          />
        </div>
        <span
          v-if="getView.full"
          class="block ml-5 text-lg font-ubuntu font-bold text-gray-dark"
          v-text="`${date.month.text} ${date.year.text}`"
        />
      </div>
    </div>
    <div
      :class="{ 'overflow-x-auto overflow-y-hidden rounded shadow-md bg-gray-soft': getView.full }"
    >
      <ol
        :style="getView.reduced ? 'height: 38px' : undefined"
        :class="[
          'grid grid-cols-7 auto-cols-fr',
          getView.full ? 'overflow-hidden rounded-t gap-px px-px' : 'items-center'
        ]"
      >
        <li
          v-for="(day, indexDay) in date.weekDays"
          :key="indexDay"
          :class="
            getView.full
              ? [
                  'overflow-hidden',
                  { 'rounded-tl': !indexDay },
                  { 'rounded-tr': indexDay === date.weekDays.length - 1 }
                ]
              : ''
          "
          :style="getView.full ? 'padding: 1px 0 0.5px 0px' : undefined"
        >
          <span
            :class="[
              'block text-center text-gray-light',
              getView.full
                ? 'p-3 md:p-4 md:text-right text-xs font-bold uppercase'
                : 'font-semibold',
              indexDay === 5 || indexDay === 6 ? 'bg-opacity-90 bg-white' : 'bg-white'
            ]"
            v-text="day"
          />
        </li>
      </ol>
      <div :class="{ 'pb-px rounded-b': getView.full }">
        <ol
          v-for="(week, indexWeek) in arrayOfDays"
          :key="indexWeek"
          :class="[
            'grid grid-cols-7 auto-cols-fr',
            { 'overflow-hidden rounded-t gap-px px-px': getView.full },
            { 'rounded-b': indexWeek === arrayOfDays.length - 1 }
          ]"
        >
          <li
            v-for="(day, indexDay) in week.dates"
            :key="indexDay"
            :style="getView.full ? 'padding: 0.5px 0' : undefined"
          >
            <div
              :class="[
                (!day.active && day.included) || !day.included
                  ? { 'opacity-60 bg-white': getView.full }
                  : getView.full
                  ? indexDay === 5 || indexDay === 6
                    ? 'bg-opacity-90 bg-white'
                    : day.today
                    ? 'bg-blue-very-light'
                    : 'bg-white'
                  : { 'bg-orange-very-light': day.prediction },
                { 'opacity-60 bg-white': !day.included && getView.reduced }
              ]"
            >
              <div
                :style="`height: ${getView.full ? (windowWidth >= 425 ? '116' : '165') : '38'}px`"
                :class="[
                  {
                    'grid auto-rows-min md:auto-rows-fr items-center gap-0.5 md:gap-1 p-1 md:p-2.5':
                      getView.full
                  }
                ]"
              >
                <div
                  :class="[
                    'flex items-center',
                    getView.full
                      ? 'w-full md:w-auto md:h-8 justify-self-end self-start row-start-1 row-end-1 md:col-start-2 md:col-end-2'
                      : 'h-full justify-center'
                  ]"
                >
                  <span
                    :class="[
                      'block',
                      getView.full
                        ? 'w-full md:w-auto text-xs font-ubuntu font-bold text-center'
                        : [
                            'h-8 w-8 flex items-center justify-center text-sm text-gray-dark',
                            [
                              'rounded-full',
                              { 'bg-blue-very-light': day.content?.volume || day.content?.time }
                            ]
                          ],
                      day.today
                        ? getView.full
                          ? 'p-1 rounded bg-blue text-white'
                          : 'p-1.5 border-2 border-blue'
                        : day.day === 1
                        ? { 'p-1 rounded bg-gray-light text-white': getView.full }
                        : { 'text-gray': getView.full }
                    ]"
                    v-text="
                      getView.full && (day.today || day.day === 1)
                        ? `${day.day} ${day.monthText}`
                        : day.day
                    "
                  />
                </div>
                <template v-if="day.content">
                  <div
                    v-if="day.content.volume || day.content.time"
                    class="flex flex-wrap items-start justify-center self-end row-start-3 row-end-3 md:row-start-2 md:row-end-2 md:col-start-1 md:col-end-3 -m-0.5 lg:-m-1"
                  >
                    <template v-if="getView.full">
                      <div class="flex items-center justify-center md:justify-end m-0.5 lg:m-1">
                        <span
                          class="text-center md:text-left text-xs md:text-sm text-blue"
                          v-text="'Riego'"
                        />
                        <!-- <Icon
                        name="kpi-irrigation-and-fertilization"
                        :size="windowWidth >= 1280 ? '2' : windowWidth >= 768 ? '1.5' : '1'"
                        color="blue"
                      /> -->
                      </div>
                      <div class="flex flex-col m-0.5 lg:m-1">
                        <span
                          v-if="day.content.volume"
                          :class="[
                            'block text-center md:text-left text-xs md:text-sm text-blue',
                            { 'font-semibold': day.today }
                          ]"
                          v-text="day.content.volume"
                        />
                        <span
                          v-if="day.content.time"
                          :class="[
                            'block text-center md:text-left text-xs md:text-sm text-blue',
                            { 'font-semibold': day.today }
                          ]"
                          v-text="day.content.time"
                        />
                      </div>
                    </template>
                  </div>
                  <div
                    v-if="getView.full"
                    :class="[
                      'md:h-8 flex flex-col 2xl:flex-row 2xl:flex-wrap items-center 2xl:items-center self-start row-start-2 row-end-2 md:row-start-1 md:row-end-1 md:col-start-1 md:col-end-3',
                      day.content.rainIcon
                        ? 'md:items-start'
                        : {
                            'md:items-end': day.content.rainPercentage || day.content.rainLM
                          }
                    ]"
                  >
                    <div
                      v-if="day.content.rainIcon"
                      class="flex items-center justify-center md:justify-end mr-0.5 2xl:mr-1.5"
                    >
                      <Icon :name="`pt-weather-${day.content.rainIcon}`" type="svg" size="0.75" />
                    </div>
                    <div
                      v-if="day.content.rainPercentage || day.content.rainLM"
                      class="flex flex-wrap justify-center -my-0.5"
                    >
                      <span
                        v-if="day.content.rainPercentage"
                        :class="[
                          'block m-0.5 text-center md:text-left text-xs text-gray',
                          { 'font-semibold': day.today }
                        ]"
                        v-text="day.content.rainPercentage"
                      />
                      <span
                        v-if="day.content.rainLM"
                        :class="[
                          'block m-0.5 text-center md:text-left text-xs text-gray',
                          { 'font-semibold': day.today }
                        ]"
                        v-text="day.content.rainLM"
                      />
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </li>
        </ol>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable max-lines */
import Icon from '@/components/Icon';
import { ref, reactive, toRefs, computed, onMounted, onUnmounted } from 'vue';
import { day, helpers } from '@pt/utilities';
export default {
  name: 'PtCalendar',
  components: { Icon },
  props: {
    title: {
      type: String,
      default: ''
    },
    content: {
      type: Array,
      default: () => [{}]
    },
    view: {
      type: String,
      default: 'full',
      validator: val => /reduced|full/.test(val)
    }
  },
  setup(props) {
    const windowWidth = ref(window.innerWidth);
    const state = reactive({
      current: {
        date: day.getNow(),
        day: day.getToday('YYYY-MM-DD')
      }
    });
    const { current } = toRefs(state);
    const calendarLegends = [
      { point: 'p-1 rounded-full border-2 border-blue', text: 'Día actual' },
      { point: 'p-1.5 rounded-full bg-blue-very-light', text: 'Día Riego' },
      { point: 'p-1.5 bg-orange-very-light', text: 'Predicción' }
    ];
    const onWidthChange = () => (windowWidth.value = window.innerWidth);
    onMounted(() => window.addEventListener('resize', onWidthChange));
    onUnmounted(() => window.removeEventListener('resize', onWidthChange));
    const goToToday = () => (current.value.date = day.getNow());
    const formateDateObject = date => {
      const clonedObject = { ...date.toObject() };
      const formattedDate = date.format('YYYY-MM-DD');
      const getContent =
        props.content != null && Array.isArray(props.content)
          ? props.content.find(c => c.date === formattedDate)
          : false;
      return {
        day: clonedObject.date,
        month: clonedObject.months,
        monthText: date.format('MMM'),
        year: clonedObject.years,
        today: date.isToday(),
        formatted: formattedDate,
        included: clonedObject.months === current.value.date.month(),
        content: getContent,
        prediction: date.isToday() || (new Date(formattedDate) > new Date() && !!getContent),
        active: !!getContent
      };
    };
    const getView = computed(() => ({
      reduced: props.view === 'reduced',
      full: props.view === 'full'
    }));
    const getRange = computed(() => {
      const getMin = props.content[0]?.date;
      const getMax = props.content[props.content.length - 1]?.date;
      const min = day.getFormattedDate(getMin, 'YYYY-MM-DD') ?? null;
      const max = day.getFormattedDate(getMax, 'YYYY-MM-DD') ?? null;
      return {
        ...(min && { min: new Date(getMin) }),
        ...(max && { max: new Date(getMax) })
      };
    });
    const date = computed(() => ({
      month: {
        text: helpers.firstLetterUppercase(current.value.date.format('MMMM')),
        current: {
          min: getRange.value.min
            ? new Date(current.value.date.format('YYYY-MM-DD')) >= getRange.value.min
            : true,
          max: getRange.value.max
            ? new Date(current.value.date.format('YYYY-MM-DD')) < getRange.value.max
            : true
        }
      },
      year: {
        text: helpers.uppercase(current.value.date.format('YYYY'))
      },
      weekDays: (() => {
        const singleLetterDays = ['L', 'M', 'X', 'J', 'V', 'S', 'D'];
        return Array.from({ length: 7 }, (_, i) => {
          const days = day.getNow().weekday(i);
          const formattedDays = days.format(
            windowWidth.value >= 640 ? 'dddd' : windowWidth.value >= 425 ? 'ddd' : 'dd'
          );
          return getView.value.full
            ? helpers.firstLetterUppercase(formattedDays.replace('.', ''))
            : singleLetterDays[i];
        });
      })()
    }));
    const changeMonth = operation => {
      let setOpr;
      if (operation === '+' && date.value.month.current.max) setOpr = 'add';
      else if (operation === '-' && date.value.month.current.min) setOpr = 'subtract';
      if (setOpr != null) current.value.date = current.value.date[setOpr](1, 'month');
    };
    const arrayOfDays = computed(() => {
      let currentDate = current.value.date.startOf('month').weekday(0);
      const nextMonth = current.value.date.add(1, 'month').month();
      const allDates = [];
      let weekDates = [];
      let weekCounter = 1;
      while (currentDate.weekday(0).toObject().months !== nextMonth) {
        const formatted = formateDateObject(currentDate);
        weekDates.push(formatted);
        if (weekCounter === 7) {
          allDates.push({ dates: weekDates });
          weekDates = [];
          weekCounter = 0;
        }
        weekCounter++;
        currentDate = currentDate.add(1, 'day');
      }
      return allDates;
    });
    return {
      date,
      current,
      getView,
      getRange,
      goToToday,
      changeMonth,
      arrayOfDays,
      windowWidth,
      calendarLegends
    };
  }
};
</script>
