<template>
  <TransitionRoot as="template" :show="open">
    <HeadlessDialog
      as="div"
      :class="[
        'fixed w-screen h-screen z-50 inset-0',
        { 'overflow-y-auto': maxWidth !== 'screen' }
      ]"
      @close="showModal"
    >
      <div
        class="flex items-center sm:items-end justify-center min-h-screen pt-4 px-4 lg:pb-20 text-center sm:block sm:p-0"
      >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay class="fixed inset-0 bg-gray bg-opacity-75 transition-opacity" />
        </TransitionChild>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
          &#8203;
        </span>
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            :class="[
              'w-full inline-block align-bottom px-4 pt-5 pb-4 sm:my-8 sm:align-middle sm:p-6 text-left overflow-hidden shadow-xl transform transition-all rounded-lg bg-white',
              getMaxWidth
            ]"
          >
            <div v-if="iconName" class="mx-auto flex items-center justify-center rounded-full">
              <Icon :color="iconColor" :name="iconName" :type="iconType" :size="iconSize" />
            </div>
            <div tabindex="0" :class="iconName && 'mt-3 sm:mt-5'" class="text-center">
              <DialogTitle as="span" class="text-base leading-6 font-bold">{{ title }}</DialogTitle>
              <div v-if="hasSlot('content')" :class="title && 'mt-10'">
                <Icon
                  v-if="showCloseIcon"
                  class="pt-icon pt-close-circle text-gray cursor-pointer absolute top-1 right-1 text-xl"
                  name="pt-close-circle"
                  @click="showModal"
                />
                <slot name="content">
                  <span v-if="description" class="font-light" v-text="description" />
                </slot>
              </div>
            </div>
            <div
              v-if="hasSlot('actions')"
              class="mt-5 sm:mt-6 grid gap-3 grid-flow-row-dense sm:grid-flow-col-dense"
            >
              <slot name="actions" />
            </div>
          </div>
        </TransitionChild>
      </div>
    </HeadlessDialog>
  </TransitionRoot>
</template>
<script>
import slot from '@/mixins/slot';
import {
  DialogTitle,
  DialogOverlay,
  TransitionRoot,
  TransitionChild,
  Dialog as HeadlessDialog
} from '@headlessui/vue';
import Icon from '@/components/Icon';
import { computed, watch } from 'vue';
export default {
  name: 'PtModal',
  components: {
    Icon,
    DialogTitle,
    DialogOverlay,
    TransitionRoot,
    HeadlessDialog,
    TransitionChild
  },
  props: {
    open: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    iconName: {
      type: String,
      default: ''
    },
    iconType: {
      type: String,
      default: 'font',
      validator: val => /font|svg/.test(val)
    },
    iconSize: {
      type: String,
      default: ''
    },
    iconColor: {
      type: String,
      default: 'gray'
    },
    showCloseIcon: {
      type: Boolean,
      default: false
    },
    maxWidth: {
      type: String,
      default: 'lg',
      validator: val => /xs|sm|md|lg|xl|2xl|3xl|screen/.test(val)
    }
  },
  emits: ['update:open'],
  setup(props, { emit }) {
    const { hasSlot } = slot();
    const showModal = () => emit('update:open', !props.open);
    const getMaxWidth = computed(() => {
      switch (props.maxWidth) {
        case 'xs':
          return 'sm:max-w-xs';
        case 'md':
          return 'sm:max-w-sm';
        case 'lg':
          return 'sm:max-w-md';
        case 'xl':
          return 'sm:max-w-xl';
        case '2xl':
          return 'sm:max-w-2xl';
        case '3xl':
          return 'sm:max-w-3xl';
        case 'screen':
          return 'sm:max-w-full';
        default:
          return 'sm:max-w-lg';
      }
    });
    watch(
      () => props.open,
      () => {
        document.body.classList.toggle('no-scroll');
        window.addEventListener('single-spa:routing-event', () => {
          if (document.body.classList.contains('no-scroll'))
            document.body.classList.remove('no-scroll');
        });
      }
    );
    return {
      hasSlot,
      showModal,
      getMaxWidth
    };
  }
};
</script>
