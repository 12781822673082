<template>
  <div class="w-full overflow-x-auto rounded-lg border border-gray-very-light pt-scroll">
    <div class="min-w-full flex">
      <ul role="list" class="flex flex-nowrap py-2 divide-x divide-gray-very-light bg-white">
        <li class="w-28 h-36">
          <div
            v-if="firstWeatherHour"
            class="w-full h-full flex flex-col items-center justify-between p-2 space-y-1 text-center"
          >
            <span class="mb-1 font-bold">{{ todayText }}</span>
            <icon :name="`pt-weather-${firstWeatherHour.iconCode}`" type="svg" />
            <span class="font-bold mb-1">{{ setTemperature(firstWeatherHour.temperature) }} </span>
            <span class="text-xs font-medium text-gray mb-1">
              {{ setWind(firstWeatherHour.windDirCardinal, firstWeatherHour.windSpeed) }}
            </span>
          </div>
        </li>
        <li v-for="(weather, index) in weatherHours" :key="index" class="w-28 h-36">
          <div
            class="w-full h-full flex flex-col items-center justify-between p-2 space-y-1 text-center"
          >
            <span class="mb-1">{{ setDate(weather.startTime, index) }}</span>
            <icon :name="`pt-weather-${weather?.iconCode}`" type="svg" />
            <span class="font-bold mb-1">{{ setTemperature(weather.temperature) }} </span>
            <span class="text-xs font-medium text-gray mb-1">
              {{ setWind(weather.windDirCardinal, weather.windSpeed) }}
            </span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { computed } from 'vue';
import Icon from '@/components/Icon';

export default {
  name: 'PtWeatherList',
  components: {
    Icon
  },
  props: {
    weatherHours: {
      type: Object,
      default: () => {}
    },
    dynamicText: {
      type: Object,
      default: () => {}
    },
    todayText: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const setDate = date => {
      const dateTime = new Date(date.replace(' ', 'T'));
      const formattedDate = `${dateTime.getHours()}:${
        (dateTime.getMinutes() < 10 ? '0' : '') + dateTime.getMinutes()
      }`;
      return formattedDate.toString();
    };
    const firstWeatherHour = computed(() =>
      props.weatherHours && props.weatherHours[0] ? props.weatherHours[0] : false
    );
    const setWind = (windDirCardinal, windSpeed) => {
      if (windDirCardinal && windSpeed) {
        return `${windDirCardinal} - ${windSpeed} Km/h`;
      }
    };

    const setTemperature = temperature => {
      if (temperature) {
        return `${temperature}º`;
      }
    };
    return {
      setDate,
      setWind,
      setTemperature,
      firstWeatherHour
    };
  }
};
</script>
