<template>
  <button
    type="button"
    class="inline-flex h-fit items-center justify-center text-sm font-bold rounded shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-light bg-transparent hover:bg-white-dark border-blue border-2"
  >
    <slot name="content" />
  </button>
</template>
<script>
export default {
  name: 'PtTypeBordered'
};
</script>
