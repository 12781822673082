<template>
  <nav :class="classNav" aria-label="Sidebar">
    <component
      :is="hideRoute || checkURL(standItem) ? 'a' : 'router-link'"
      v-if="standItem"
      :to="!hideRoute && !checkURL(standItem) ? setterHref(standItem) : null"
      :href="!hideRoute && checkURL(standItem) ? setterHref(standItem) : null"
      :class="[
        'flex items-center p-3 mb-px text-sm text-gray font-bold cursor-pointer',
        `${!isMobile && 'rounded-md'}`,
        { 'hover:text-blue hover:bg-blue-very-light': !hideHover },
        { 'text-blue bg-blue-very-light': currentItem === setterValue(standItem) && !hideHover }
      ]"
      @click="toggleCurrent(standItem)"
    >
      <icon
        v-if="setterIcon(standItem)"
        :name="setterIcon(standItem)"
        class="w-7 mr-2 text-2xl"
        :color="currentItem === setterValue(standItem) && !hideHover ? 'blue' : ''"
        aria-hidden="true"
      />
      <span :class="`${currentItem === setterValue(standItem) && !hideHover ? 'text-blue' : ''}`">
        {{ setterName(standItem) }}
      </span>
    </component>
    <hr
      v-if="standItem && !isMobile"
      role="separator"
      aria-orientation="horizontal"
      class="mb-4 mt-2 border-gray-very-light"
    />
    <template v-if="Array.isArray(items)">
      <component
        :is="!item.enable || checkURL(item) ? 'a' : 'router-link'"
        v-for="(item, index) in items"
        :key="index"
        :to="!checkURL(item) ? setterHref(item) : null"
        :target="item.target ?? undefined"
        :href="checkURL(item) ? setterHref(item) : null"
        :class="getClassItem(item, item.enable)"
        @click="toggleCurrent(item)"
      >
        <template v-if="item.svg">
          <img :src="require(`@/assets/${item.svg}`)" />
          <icon
            v-if="setterIcon(item)"
            :name="setterIcon(item)"
            class="w-7 ml-2 text-lg"
            :color="currentItem === setterValue(item) && !hideHover ? 'blue' : ''"
            aria-hidden="true"
          />
        </template>
        <template v-else>
          <icon
            v-if="setterIcon(item)"
            :name="setterIcon(item)"
            class="w-7 mr-2 text-2xl"
            :color="currentItem === setterValue(item) && !hideHover ? 'blue' : ''"
            aria-hidden="true"
          />
          <span :class="`${currentItem === setterValue(item) && !hideHover ? 'text-blue' : ''}`">
            {{ item.name }}
          </span>
        </template>
      </component>
    </template>
    <template v-else>
      <component
        :is="hideRoute || checkURL(items) ? 'a' : 'router-link'"
        :to="!hideRoute && !checkURL(items) ? setterHref(items) : null"
        :href="!hideRoute && checkURL(items) ? setterHref(items) : null"
        :class="[
          'flex items-center p-3 mb-px text-sm text-gray font-bold cursor-pointer',
          `${!isMobile && 'rounded-md'}`,
          { 'hover:text-blue hover:bg-blue-very-light': !hideHover },
          { 'text-blue bg-blue-very-light': currentItem === setterValue(item) && !hideHover }
        ]"
        @click="toggleCurrent(items)"
      >
        <icon
          v-if="setterIcon(items)"
          :name="setterIcon(items)"
          class="w-7 mr-2 text-2xl"
          :color="currentItem === setterValue(items) && !hideHover ? 'blue' : ''"
          aria-hidden="true"
        />
        <span :class="`${currentItem === setterValue(items) && !hideHover ? 'text-blue' : ''}`">
          {{ setterName(items) }}
        </span>
      </component>
    </template>
  </nav>
</template>
<script>
import Icon from '@/components/Icon';
import { useRoute } from 'vue-router';
import { computed, inject } from 'vue';
import dynamicElements from '@/mixins/dynamicElements';
export default {
  name: 'PtSidebarNav',
  components: { Icon },
  props: {
    classNav: {
      type: String,
      default: ''
    }
  },
  emits: ['clickItem'],
  setup(_, { emit }) {
    const route = useRoute();
    const href = inject('href');
    const icon = inject('icon');
    const name = inject('name');
    const items = inject('items');
    const value = inject('value');
    const isMobile = inject('isMobile');
    const standItem = inject('standItem');
    const hideHover = inject('hideHover');
    const hideRoute = inject('hideRoute');
    const { setElement } = dynamicElements();
    const currentItem = computed(() =>
      route.path === '/'
        ? 'home'
        : Object.keys(route.params).length > 0
        ? route.params[route.name.toLowerCase()]
        : route.name.toLowerCase()
    );
    const setterValue = item => setElement(item, value);
    const setterHref = item => setElement(item, href);
    const setterIcon = item => setElement(item, icon);
    const setterName = item => setElement(item, name);
    const toggleCurrent = item => emit('clickItem', item);
    const getClassItem = (item, enable) => {
      if (enable) {
        return [
          'flex items-center p-3 mb-px text-sm text-gray font-bold cursor-pointer',
          `${!isMobile.value && 'rounded-md'}`,
          { 'hover:text-blue hover:bg-blue-very-light': !hideHover.value },
          {
            'text-blue bg-blue-very-light':
              currentItem.value === setterValue(item) && !hideHover.value
          }
        ];
      } else {
        return [
          'flex items-center p-3 mb-px text-sm text-gray-light font-bold cursor-pointer',
          `${!isMobile.value && 'rounded-md'}`,
          { 'hover:text-gray-light hover:bg-gray-very-light': !hideHover.value },
          {
            'text-gray-light bg-gray-very-light':
              currentItem.value === setterValue(item) && !hideHover.value
          }
        ];
      }
    };
    const checkURL = item => {
      const url = setterHref(item);
      const regex =
        /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/|ftp:\/\/|ftps:\/\/|blob:|localhost)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
      return regex.test(url);
    };
    return {
      items,
      checkURL,
      isMobile,
      standItem,
      hideHover,
      hideRoute,
      setterHref,
      setterIcon,
      setterName,
      currentItem,
      setterValue,
      getClassItem,
      toggleCurrent
    };
  }
};
</script>
