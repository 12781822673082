<template>
  <button
    type="button"
    class="inline-flex h-fit items-center justify-center border border-transparent text-sm font-bold rounded shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-light bg-blue hover:bg-blue-light"
  >
    <slot name="content" />
  </button>
</template>
<script>
export default {
  name: 'PtTypePrimary'
};
</script>
