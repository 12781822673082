// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `[data-v-190f0088]::-moz-placeholder {
  --tw-text-opacity: 1;
  color: rgba(153, 153, 153, var(--tw-text-opacity));
}
[data-v-190f0088]::placeholder {
  --tw-text-opacity: 1;
  color: rgba(153, 153, 153, var(--tw-text-opacity));
}
`, "",{"version":3,"sources":["webpack://./src/components/Search/Box/index.vue","webpack://./node_modules/tailwindcss/lib/lib/substituteClassApplyAtRules.js"],"names":[],"mappings":"AA0CA;ECxCA,oBAAmB;EAAnB,kDAAmB;AD0CnB;AAFA;ECxCA,oBAAmB;EAAnB,kDAAmB;AD0CnB","sourcesContent":["<template>\n  <div>\n    <div class=\"relative w-full flex items-center rounded border overflow-hidden border-gray-soft\">\n      <Icon class=\"py-3 pl-3\" size=\"1.2\" name=\"pt-search\" />\n      <input\n        v-appear=\"box.focus\"\n        class=\"pt-form-search\"\n        :placeholder=\"box.placeholder\"\n        type=\"text\"\n        :value=\"box.search\"\n        @input=\"toSearch\"\n      />\n    </div>\n  </div>\n</template>\n<script>\nimport { inject } from 'vue';\nimport Icon from '@/components/Icon';\nexport default {\n  name: 'PtSearchBox',\n  directives: {\n    appear: {\n      mounted(el, binding) {\n        if (binding.value) el.focus();\n      }\n    }\n  },\n  components: { Icon },\n  emits: ['setSearch'],\n  setup(_, { emit }) {\n    const box = inject('box');\n    const toSearch = e => {\n      emit('setSearch', e.target.value);\n    };\n    return {\n      box,\n      toSearch\n    };\n  }\n};\n</script>\n<style scoped>\n::placeholder {\n  @apply text-gray-light;\n}\n</style>\n","@tailwind base;\n@tailwind components;\n@tailwind utilities;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
