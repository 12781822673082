<template>
  <div>
    <div
      class="w-full h-full flex flex-col overflow-hidden rounded-lg border border-gray-very-light shadow-lg"
    >
      <input
        v-if="!hideSearch"
        ref="input"
        :value="searchState.global"
        type="text"
        placeholder="Busque la ubicación de su finca"
        class="p-2 border-none select-all"
        @input="setSearchValue"
      />
      <div class="relative h-full">
        <vue-google-maps
          :map-config="mapConfig"
          :api-key="apiKey"
          :libraries="mapLibraries"
          class="h-full"
          @google-map-updated="onGoogleMapUpdated"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { ref, toRefs, reactive } from 'vue';
import { env } from '@pt/env-config';
import VueGoogleMaps from '@point-hub/vue-google-maps';
export default {
  name: 'PtMap',
  components: {
    VueGoogleMaps
  },
  props: {
    coordinates: {
      type: Object,
      required: true
    },
    hideSearch: {
      type: Boolean,
      default: false
    }
  },
  emits: ['changeCoordinates', 'searchState'],
  setup(props, { emit }) {
    const input = ref(null);
    const searchState = reactive({
      global: ''
    });
    const config = reactive({
      apiKey: env.APP_GOOGLE_MAPS_KEY,
      mapConfig: {
        zoom: !props.hideSearch ? 13 : 17,
        center: {
          lat: +props.coordinates.lat,
          lng: +props.coordinates.lng
        },
        restriction: {
          latLngBounds: {
            east: 5.498318030841596,
            north: 44.64694432060104,
            south: 25.98887073886116,
            // eslint-disable-next-line no-loss-of-precision
            west: -30.168674156658403
          }
        },
        zoomControl: true,
        mapTypeControl: true,
        mapTypeId: !props.hideSearch ? 'roadmap' : 'satellite',
        mapTypeControlOptions: {
          mapTypeIds: ['roadmap', 'satellite']
        },
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUi: true,
        styles: [
          {
            featureType: 'administrative',
            elementType: 'geometry',
            stylers: [{ visibility: 'off' }]
          },
          {
            featureType: 'landscape.man_made',
            stylers: [{ visibility: 'off' }]
          },
          {
            featureType: 'poi',
            stylers: [{ visibility: 'off' }]
          },
          {
            featureType: 'transit',
            stylers: [{ visibility: 'off' }]
          }
        ]
      },
      mapLibraries: ['places']
    });
    const state = reactive({
      map: {
        state: null,
        google: null,
        autocomplete: null,
        place: null,
        marker: null,
        geocoder: null
      }
    });
    const { apiKey, mapConfig, mapLibraries } = toRefs(config);
    const { global } = toRefs(searchState);
    const { map } = toRefs(state);
    const addressType = {
      country: 'country',
      administrative1: 'administrative_area_level_1',
      administrative4: 'administrative_area_level_4',
      administrative3: 'administrative_area_level_3',
      route: 'route'
    };
    const setterPlace = results => {
      global.value = results[0].formatted_address;
      const city = {
        city: [
          results.find(item => item.types.includes(addressType.administrative4))
            ?.address_components[0].long_name,
          results.find(item => item.types.includes(addressType.administrative3))
            ?.address_components[0].long_name,
          results.find(item => item.types.includes(addressType.route))?.address_components[2]
            .long_name
        ].filter(c => c != null),
        cityShortName: results.find(item => item.types.includes(addressType.route))
          ?.address_components[2].short_name
      };
      const province = {
        province: [
          results.find(item => item.types.includes(addressType.administrative4))
            ?.address_components[1].long_name,
          results.find(item => item.types.includes(addressType.route))?.address_components[2]
            ?.long_name
        ].filter(p => p != null),
        provinceShortName: results.find(item => item.types.includes(addressType.route))
          ?.address_components[2]?.short_name
      };
      const ca = {
        ca: results.find(item => item.types.includes(addressType.administrative1))
          ?.address_components[0].long_name,
        caShortName: results.find(item => item.types.includes(addressType.administrative1))
          ?.address_components[0].short_name
      };
      const country = {
        country: results.find(item => item.types.includes(addressType.country))
          ?.address_components[0].long_name,
        countryShortName: results.find(item => item.types.includes(addressType.country))
          ?.address_components[0].short_name
      };
      emit('searchState', { global: results[0].formatted_address, city, province, ca, country });
    };
    const onGoogleMapUpdated = mapState => {
      map.value.state = mapState.map;
      map.value.google = mapState.google;
      map.value.marker = new mapState.google.maps.Marker({
        map: mapState.map,
        position: new google.maps.LatLng(+props.coordinates.lat, +props.coordinates.lng),
        draggable: !props.hideSearch,
        animation: mapState.google.maps.Animation.DROP
      });
      if (!props.hideSearch) {
        map.value.geocoder = new map.value.google.maps.Geocoder();
        map.value.geocoder.geocode(
          { location: { lat: +props.coordinates.lat, lng: +props.coordinates.lng } },
          (results, status) => {
            if (status === 'OK') {
              setterPlace(results);
            }
          }
        );
        map.value.google.maps.event.addListener(map.value.marker, 'dragend', ({ latLng }) => {
          map.value.geocoder.geocode({ latLng }, (results, status) => {
            if (status === 'OK') {
              setterPlace(results);
            }
          });
          emit('changeCoordinates', { lat: latLng.lat(), lng: latLng.lng() });
        });
        map.value.google.maps.event.addListener(map.value.state, 'click', ({ latLng }) => {
          map.value.geocoder.geocode({ latLng }, (results, status) => {
            if (status === 'OK') {
              setterPlace(results);
            }
          });
          emit('changeCoordinates', { lat: latLng.lat(), lng: latLng.lng() });
          map.value.marker.setPosition({ lat: latLng.lat(), lng: latLng.lng() });
        });
      }
    };
    const setSearchValue = e => {
      if (e.target.value.length < 3 && !props.hideSearch) {
        e.stopImmediatePropagation();
      } else {
        if (!map.value.autocomplete) {
          map.value.autocomplete = new map.value.google.maps.places.Autocomplete(input.value, {
            componentRestrictions: { country: 'es' }
          });
          map.value.autocomplete.bindTo('bounds', map.value.state);
        }
        map.value.google.maps.event.addListener(map.value.autocomplete, 'place_changed', () => {
          map.value.place = map.value.autocomplete.getPlace();
          if (map.value.place.geometry) {
            global.value = map.value.place.formatted_address;
            map.value.geocoder.geocode(
              {
                location: {
                  lat: map.value.place.geometry.location.lat(),
                  lng: map.value.place.geometry.location.lng()
                }
              },
              (results, status) => {
                if (status === 'OK') {
                  setterPlace(results);
                }
              }
            );
            map.value.state.setCenter(map.value.place.geometry.location);
            map.value.marker.setPosition(map.value.place.geometry.location);
            emit('changeCoordinates', {
              lat: map.value.marker.getPosition().lat(),
              lng: map.value.marker.getPosition().lng()
            });
          }
        });
      }
    };
    const setExternalCoordinates = (lat, lng) => {
      const setterLatLng = {
        lat: isNaN(+lat) ? 0 : +lat,
        lng: isNaN(+lng) ? 0 : +lng
      };
      map.value.state.panTo({ lat: setterLatLng.lat, lng: setterLatLng.lng });
      map.value.marker.setPosition({ lat: setterLatLng.lat, lng: setterLatLng.lng });
      map.value.geocoder.geocode(
        { location: { lat: setterLatLng.lat, lng: setterLatLng.lng } },
        (results, status) => {
          if (status === 'OK') {
            setterPlace(results);
          }
        }
      );
    };
    return {
      map,
      input,
      apiKey,
      mapConfig,
      searchState,
      setterPlace,
      mapLibraries,
      setSearchValue,
      onGoogleMapUpdated,
      setExternalCoordinates
    };
  }
};
</script>
