<template>
  <TransitionRoot as="template" :show="open">
    <TransitionChild
      as="template"
      enter="ease-out duration-300"
      enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      enter-to="opacity-100 translate-y-0 sm:scale-100"
      leave="ease-in duration-200"
      leave-from="opacity-100 translate-y-0 sm:scale-100"
      leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    >
      <div
        class="flex justify-center items-center w-full h-full fixed bg-loading left-0 top-0"
        style="z-index: 999"
      >
        <div
          class="w-96 flex flex-col items-center justify-center space-y-5 px-4 pt-5 pb-4 sm:p-9 text-center overflow-hidden rounded-lg shadow-md transform transition-all sm:my-8 mx-auto my-0 bg-white"
        >
          <div>
            <icon :color="icon.color" class="text-6xl" :name="icon.name" />
          </div>
          <slot name="content"></slot>
          <Form
            :id="inputId.delete"
            class="w-full"
            :hide-separator="true"
            type="input"
            :placeholder="`Escribe &quot;${conditionalText}&quot;`"
            @form-data="changedInputValue"
          />
          <div class="flex space-x-3">
            <div v-if="hasSlot('closer')" @click="cancel">
              <slot name="closer" />
            </div>
            <div>
              <Button
                :text="buttonConditionalText"
                :loading="loadingButton"
                color="white"
                :type="conditional ? 'danger' : 'disabled'"
                @click="confirm"
              />
            </div>
          </div>
        </div>
      </div>
    </TransitionChild>
  </TransitionRoot>
</template>

<script>
import slot from '@/mixins/slot';
import { ref, computed } from 'vue';
import { TransitionRoot, TransitionChild } from '@headlessui/vue';
import Button from '../../Button';
import Form from '../../Form';
import Icon from '@/components/Icon';
export default {
  name: 'PtPopupConditional',
  // eslint-disable-next-line vue/no-reserved-component-names
  components: { TransitionRoot, TransitionChild, Button, Form, Icon },
  props: {
    icon: {
      type: Object,
      default: () => ({
        name: 'pt-trash-alt',
        color: 'red'
      })
    },
    conditionalText: {
      type: String,
      default: ''
    },
    buttonConditionalText: {
      type: String,
      default: ''
    },
    loadingButton: {
      type: Boolean,
      default: false
    }
  },
  emits: ['confirm', 'cancel'],
  setup(props, { emit }) {
    const { hasSlot } = slot();
    const open = ref(props.show);
    const inputValue = ref('');
    const inputId = {
      delete: 'deleteInput'
    };
    const changedInputValue = data => {
      const { value } = data.value;
      inputValue.value = value;
    };
    const conditional = computed(() => props.conditionalText == inputValue.value);
    const confirm = () => {
      emit('confirm');
    };
    const cancel = () => {
      emit('cancel');
    };
    return {
      open,
      cancel,
      inputId,
      hasSlot,
      confirm,
      conditional,
      changedInputValue
    };
  }
};
</script>
