<template>
  <div>
    <template v-if="chartMode.apex || chartMode.bar">
      <span v-if="titles" v-text="titles" />
      <ApexChart v-if="chartMode.apex" />
      <BarChart v-else />
    </template>
    <PlotlyChart v-else-if="chartMode.plotly" />
  </div>
</template>

<script>
import { computed } from 'vue';
import BarChart from './Bar';
import ApexChart from './Apex';
import PlotlyChart from './Plotly';
export default {
  name: 'PtChart',
  components: {
    BarChart,
    ApexChart,
    PlotlyChart
  },
  provide() {
    return {
      global: computed(() => ({
        type: this.type,
        data: this.data,
        xaxis: this.xaxis,
        colors: this.colors
      })),
      apex: computed(() => ({
        labels: this.labels
      })),
      plotly: computed(() => ({
        id: this.id,
        locale: this.locale,
        titles: this.titles,
        static: this.static,
        height: this.height,
        existing: this.existing,
        annotation: this.annotation
      }))
    };
  },
  props: {
    mode: {
      type: String,
      default: 'apex',
      validator: val => /apex|plotly|bar/.test(val)
    },
    data: {
      type: [Array, Number],
      default: () => []
    },
    xaxis: {
      type: [Array, Number],
      default: () => []
    },
    type: {
      type: String,
      default: ''
    },
    labels: {
      type: Array,
      default: () => [],
      validator: val =>
        Array.isArray(val) ? val.every(l => typeof l === 'string') : typeof val === 'string'
    },
    colors: {
      type: [String, Array],
      default: () => '',
      validator: val =>
        Array.isArray(val) ? val.every(l => typeof l === 'string') : typeof val === 'string'
    },
    titles: {
      type: [String, Array],
      default: '',
      validator: val =>
        Array.isArray(val)
          ? val.every(l => typeof l === 'string' || l == null) && val.length <= 4
          : typeof val === 'string' || val == null
    },
    id: {
      type: String,
      default: 'pt-plotly'
    },
    locale: {
      type: String,
      default: 'es'
    },
    static: {
      type: Boolean,
      default: false
    },
    height: {
      type: [String, Number, typeof null],
      default: null,
      validator: val => !isNaN(val) || val == null
    },
    annotation: {
      type: String,
      default: ''
    },
    existing: {
      type: [String, Array],
      default: ''
    }
  },
  setup(props) {
    const chartMode = computed(() => ({
      bar: props.mode === 'bar',
      apex: props.mode === 'apex',
      plotly: props.mode === 'plotly'
    }));
    return {
      chartMode
    };
  }
};
</script>
