<template>
  <div class="pt-event-megamenu">
    <div class="flex flex-col">
      <div class="w-full relative">
        <img loading="lazy" class="w-full rounded-t" :src="cardInfo?.main_image.url" />
        <span class="pt-published absolute bottom-2 left-2">
          {{
            new Date(cardInfo?.finish_date)
              .toLocaleDateString('es-ES', { day: 'numeric', month: 'long', year: 'numeric' })
              .replace(/ de /g, ' ')
          }}
        </span>
      </div>
      <div class="flex flex-col w-full p-4">
        <div class="pb-2 flex flex-col">
          <a
            :href="`${spaceUrl}${COURSE_BASE_URL}/${cardInfo?.slug}#sso`"
            class="pt-titleEvent pb-1 cursor-pointer hover:underline text-mobile"
          >
            {{ cardInfo?.title }}
          </a>
          <Labels
            v-if="cardInfo?.tags?.length"
            label-type="transparent"
            :items="cardInfo?.tags"
            :space-url="spaceUrl"
            to-search
          />
        </div>
        <div class="flex justify-between pt-2">
          <span
            v-if="cardInfo?.event_features.price !== 0 && cardInfo?.event_features.price !== null"
            class="pt-cardFooter text-green capitalize"
          >
            {{
              roundPrice(cardInfo?.event_features.price) +
              CURRENCY[cardInfo?.event_features.currency]
            }}
          </span>
          <span v-else class="pt-cardFooter text-green">
            {{ uppercase($t('freeEvent')) }}
          </span>
          <span class="pt-cardFooter text-white capitalize">
            {{ EVENTFORMAT[cardInfo?.event_features.event_format] }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { helpers } from '@pt/utilities';
import { enums } from '@pt/env-config';
import Labels from '@/components/Labels';
export default {
  name: 'CardEventMegamenu',
  components: {
    Labels
  },
  props: {
    cardInfo: {
      type: Object,
      default: () => {}
    },
    spaceUrl: { type: String, default: '' }
  },
  setup() {
    const uppercase = helpers.uppercase;
    const roundPrice = helpers.roundPrice;
    const { CURRENCY, EVENTFORMAT, COURSE_BASE_URL } = enums;

    return {
      uppercase,
      roundPrice,
      CURRENCY,
      EVENTFORMAT,
      COURSE_BASE_URL
    };
  }
};
</script>

<style scoped lang="scss">
.pt-event-megamenu {
  width: 100%;
  border-radius: 0.5rem;
  background-color: rgb(7 78 96 / var(--tw-bg-opacity, 1));

  .gradient-overlay {
    position: absolute;
    z-index: 1;
    inset: 0;
    border-radius: 0.5rem;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
  }
}

.pt-titleEvent {
  font-family: 'Ubuntu', sans-serif;
  line-height: 1.25rem;
  font-weight: 700;
  color: #fff;
}

.pt-subtitle {
  font-size: 0.875rem;
  line-height: 1.094rem;
  font-weight: 500;
  font-family: 'Ubuntu', sans-serif;
}

.pt-cardFooter {
  font-size: 0.875rem;
  line-height: 1.094rem;
  font-family: sans-serif;
  font-weight: 600;
}

.pt-published {
  background-color: #f5f7fa;
  color: #6b7280;
  font-size: 0.875rem;
  padding: 1px 0.25rem;
  font-family: sans-serif;
  font-weight: 600;
  border-radius: 0.25rem;
}
@media (max-width: 1023px) {
  .text-mobile {
    font-size: 1.125rem /* 18px */;
    line-height: 1.75rem /* 28px */;
  }
}
</style>
