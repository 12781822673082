<template>
  <div class="w-full h-5 rounded-lg overflow-hidden" style="background-color: #f4f4f4">
    <div
      class="bar h-5 rounded-l-lg"
      :class="`${globalProps.data === 100 && 'rounded-lg'} bg-${colorByType}`"
      :style="`width: ${widthBar}%; background-color: ${backgroundColor}`"
    ></div>
  </div>
</template>

<script>
import { ref, inject, computed, onMounted } from 'vue';
export default {
  name: 'PtChartBar',
  setup() {
    const globalProps = inject('global');
    const types = {
      success: 'green-alert',
      info: 'blue-light',
      warning: 'yellow-light',
      danger: 'red-light'
    };
    const widthBar = ref(0);
    const colorByType = types[globalProps.value.type];
    const backgroundColor = computed(() =>
      Array.isArray(globalProps.value.colors)
        ? globalProps.value.colors[0]
        : globalProps.value.colors
    );
    onMounted(() => {
      Vue.nextTick(() => (widthBar.value = globalProps.value.data));
    });
    return {
      widthBar,
      colorByType,
      globalProps,
      backgroundColor
    };
  }
};
</script>

<style scoped>
.bar {
  -webkit-transition: width 1s;
  transition: width 1s;
}
</style>
