<template>
  <component :is="setButtonType" :type="action" :class="`${sizeByPadding}`">
    <template #content>
      <div class="relative h-full w-full">
        <Loading v-if="loading" height-width="5" :color="loadingColor" :show-wrapper="false" />
        <div
          :class="`flex justify-center items-center ${
            iconPosition === 'left' ? 'flex-row-reverse' : 'flex-row'
          } ${loading ? 'invisible' : 'visible'}`"
        >
          <span
            v-if="text"
            :class="`sm:whitespace-nowrap text-${textSize} font-bold text-${color}`"
          >
            {{ text }}
          </span>
          <icon
            v-if="iconName"
            :class="iconPosition === 'left' ? 'mr-1' : 'ml-1'"
            :color="color"
            :name="iconName"
            :type="iconType"
            :size="iconSize"
          />
        </div>
      </div>
    </template>
  </component>
</template>
<script>
import TypePrimary from './TypePrimary';
import TypeDisabled from './TypeDisabled';
import TypeWhite from './TypeWhite';
import TypeDanger from './TypeDanger';
import TypeTransparent from './TypeTransparent';
import TypeBordered from './TypeBordered';
import Loading from '../Loading';
import Icon from '@/components/Icon';
import { computed } from 'vue';
export default {
  name: 'PtButton',
  components: {
    TypePrimary,
    TypeWhite,
    TypeDanger,
    TypeTransparent,
    TypeDisabled,
    TypeBordered,
    Icon,
    Loading
  },
  props: {
    text: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'primary',
      validator: val => /primary|disabled|white|danger|transparent|bordered/.test(val)
    },
    action: {
      type: String,
      default: 'button'
    },
    color: {
      type: String,
      default: 'gray'
    },
    sizeByPadding: {
      type: String,
      default: 'px-3.5 sm:px-4 py-3.5'
    },
    iconName: {
      type: String,
      default: ''
    },
    iconType: {
      type: String,
      default: 'font',
      validator: val => /font|svg/.test(val)
    },
    iconSize: {
      type: String,
      default: ''
    },
    iconPosition: {
      type: String,
      default: 'right',
      validator: val => /left|right/.test(val)
    },
    loading: {
      type: Boolean,
      default: false
    },
    loadingColor: {
      type: String,
      default: 'white'
    },
    textSize: {
      type: String,
      default: 'sm'
    }
  },
  setup(props) {
    const setButtonType = computed(
      () => `Type${props.type[0].toUpperCase() + props.type.slice(1)}`
    );
    return {
      setButtonType
    };
  }
};
</script>
