<template>
  <button
    type="button"
    disabled
    class="inline-flex h-fit items-center justify-center border border-transparent text-sm font-bold rounded shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 bg-gray-light"
  >
    <slot name="content" />
  </button>
</template>
<script>
export default {
  name: 'PtTypeDisabled'
};
</script>
