<template>
  <i
    :class="[useSvg ? 'pt-icon-svg' : 'pt-icon', iconname, !useSvg && color ? `text-${color}` : '']"
    :style="size ? (useSvg ? `transform: scale(${+size})` : `font-size: ${+size}rem`) : null"
  />
</template>

<script>
export default {
  name: 'PtIcon',
  props: {
    name: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: 'font',
      validator: val => /font|svg/.test(val)
    },
    color: {
      type: String,
      default: 'gray'
    },
    size: {
      type: String,
      default: ''
    }
  },
  computed: {
    iconname() {
      return !this.name.includes('pt-') ? `pt-${this.name}` : this.name;
    },
    useSvg() {
      return this.type === 'svg';
    }
  }
};
</script>

<style lang="scss" src="./icon.scss" scoped />
