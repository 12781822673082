<template>
  <button
    type="button"
    class="inline-flex h-fit items-center justify-center border border-transparent text-sm font-bold rounded shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-red-dark bg-red hover:bg-red-dark"
  >
    <slot name="content" />
  </button>
</template>
<script>
export default {
  name: 'PtTypeDanger'
};
</script>
